import {
	Section,
	SectionHeading,
	Step,
	StepContainer,
	StepLabel,
	StepsWrapper,
} from "./styles";
import { useState } from "react";

import step1Gif from "../../../../Assets/Images/Home/workProcess1.gif";
import step2Gif from "../../../../Assets/Images/Home/workProcess2.gif";
import step3Gif from "../../../../Assets/Images/Home/workProcess3.gif";
import step4Gif from "../../../../Assets/Images/Home/workProcess4.gif";

const WorkProcessSection = () => {
	const [currentStep, setCurrentStep] = useState(0);

	return (
		<Section>
			<SectionHeading>
				How
				<br />
				We Work
			</SectionHeading>
			<StepsWrapper>
				<Step>
					<div>
						<StepLabel current={currentStep === 1}>
							Step 1
						</StepLabel>
						<hr />
					</div>
					<StepContainer
						background={step1Gif}
						onMouseEnter={() => setCurrentStep(1)}
						onMouseLeave={() => setCurrentStep(0)}
					>
						<div>
							<span>Discovery & Strategy</span>
							<p>
								Define project goals and user needs. Conduct
								research and establish a clear roadmap.
							</p>
						</div>
					</StepContainer>
				</Step>
				<Step>
					<div>
						<StepLabel current={currentStep === 2}>
							Step 2
						</StepLabel>
						<hr />
					</div>
					<StepContainer
						background={step2Gif}
						onMouseEnter={() => setCurrentStep(2)}
						onMouseLeave={() => setCurrentStep(0)}
					>
						<div>
							<span>Design & Prototyping</span>
							<p>
								Creating user-centered designs and interactive
								prototypes. Making branding and strategies.
							</p>
						</div>
					</StepContainer>
				</Step>
			</StepsWrapper>
			<StepsWrapper>
				<Step>
					<div>
						<StepLabel current={currentStep === 3}>
							Step 3
						</StepLabel>
						<hr />
					</div>
					<StepContainer
						background={step3Gif}
						onMouseEnter={() => setCurrentStep(3)}
						onMouseLeave={() => setCurrentStep(0)}
					>
						<div>
							<span>Development & QA</span>
							<p>
								Building and integrating features using agile
								methodologies. Conducting thorough testing to
								ensure quality
							</p>
						</div>
					</StepContainer>
				</Step>
				<Step>
					<StepLabel current={currentStep === 4}>Step 4</StepLabel>
					<StepContainer
						background={step4Gif}
						onMouseEnter={() => setCurrentStep(4)}
						onMouseLeave={() => setCurrentStep(0)}
					>
						<div>
							<span>Launch & Optimize</span>
							<p>
								Deploying the product and execute marketing
								strategies. Monitoring performance and
								continuously improving based on feedback.
							</p>
						</div>
					</StepContainer>
				</Step>
			</StepsWrapper>
		</Section>
	);
};

export default WorkProcessSection;
