import {
	Button,
	ContextContainer,
	GrowthList,
	IphoneContainer, PhoneContent,
	Section, SectionContainer,
	SectionHeading,
	SubHeading
} from "./styles";
import React, {useRef, useEffect} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

import growthSection1 from "../../../../Assets/Images/Home/growthSection1.gif";
import growthSection2 from "../../../../Assets/Images/Home/growthSection2.gif";
import growthSection3 from "../../../../Assets/Images/Home/growthSection3.gif";
import growthSection4 from "../../../../Assets/Images/Home/growthSection4.gif";
import {useMobileDetector} from "../../../../utils/customHooks";

gsap.registerPlugin(ScrollTrigger);

const GrowthSection = () => {
	const frameRef = useRef(null);
	const contentRefs = useRef([]);
	const isMobile = useMobileDetector();
	const sections = [
		{
			imgSrc: growthSection1,
			subTitle: 'Software',
			paragraph:
				<GrowthList>
					. Web and mobile applications<br/>
					. Technical and programmatic SEO<br/>
					. Growth automation solutions<br/>
				</GrowthList>
		},
		{
			imgSrc: growthSection2,
			subTitle: 'Design',
			paragraph:
				<GrowthList>
					. User-Centric UX/UI Design<br/>
					. Innovative Branding Assets<br/>
					. Visual Content Creation<br/>
				</GrowthList>
		},
		{
			imgSrc: growthSection3,
			subTitle: 'Marketing',
			paragraph:
				<GrowthList>
					. Branding, digital presence, and organic marketing<br/>
					. Conversion-driven paid marketing<br/>
					. Ad trafficking and channel ops at scale<br/>
				</GrowthList>
		},
		{
			imgSrc: growthSection4,
			subTitle: 'Quality assurance',
			paragraph:
				<GrowthList>
					. Comprehensive Testing Strategies<br/>
					. Automated and Manual Testing<br/>
					. Performance and Load Testing<br/>
				</GrowthList>
		},
	];

	useEffect(() => {
		if (contentRefs.current[0]) {
			contentRefs.current[0].style.display = 'flex';
		}

		const scrollTrigger = ScrollTrigger.create({
			trigger: frameRef.current,
			start: "top top",
			end: () => `+=${frameRef.current.scrollHeight * 3}`, // Adjust based on the total scrollable height
			scrub: true,
			pin: true,
			// markers: true,
			onUpdate: self => {
				const progress = self.progress;
				const index = Math.floor(progress * (sections.length - 1));
				contentRefs.current.forEach((el, i) => {
					el.style.display = i === index ? 'flex' : 'none';
				});
			}
		});

		return () => scrollTrigger.kill();
	}, [sections]);

	return (
		<Section ref={frameRef}>
			{sections.map((section, index) => (
				<SectionContainer ref={(el) => (contentRefs.current[index] = el)}>
					<IphoneContainer sectionIndex={index.toString()}>
						<PhoneContent>
							<div
								key={index}
							>
								<img src={section.imgSrc} alt={section.title}/>
							</div>
						</PhoneContent>
					</IphoneContainer>
					<ContextContainer>
						<SectionHeading>
							Ways we can ensure {!isMobile && <br/>} your digital growth
						</SectionHeading>
						<SubHeading>
							{section.subTitle}
						</SubHeading>
						{section.paragraph}
						<Button>
							Explore our services
						</Button>
					</ContextContainer>
				</SectionContainer>
			))}
		</Section>
	);
}

export default GrowthSection;