import Routing from "./Routing";
import "./Assets/style.css";

const App = () => {
	return (
		<div>
			<Routing />
		</div>
	);
};

export default App;
