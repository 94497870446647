import { Section } from "./styles";
import snapshot from "../../../../Assets/Images/Services/snapshot.webp";

const Snapshot = () => {
	return (
		<Section>
			<div>
				<img src={snapshot} alt="" />
			</div>
		</Section>
	);
};

export default Snapshot;
