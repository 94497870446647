import {
	Section,
	SectionHeading,
	TextRow,
	RoundedShape,
	HeroButton,
	ShippedContainer,
} from "./styles";

import RoundedShapeImg from "../../../../Assets/Images/Services/rounded-shape.webp";
import shippedProducts from "../../../../Assets/Images/Services/shipped-products.webp";
import shippedArrow from "../../../../Assets/Images/Home/footer-arrow.svg";

import { useMobileDetector } from "../../../../utils/customHooks";

const HeroSection = () => {
	const isMobile = useMobileDetector();

	return (
		<Section>
			<SectionHeading>
				{isMobile ? (
					<>
						<TextRow>Comprehensive</TextRow>
						<TextRow>Digital</TextRow>
					</>
				) : (
					<TextRow>Comprehensive Digital</TextRow>
				)}
				<TextRow>
					Solutions to
					<RoundedShape src={RoundedShapeImg} alt={"icon"} />
				</TextRow>
				{isMobile ? (
					<>
						<TextRow>Elevate Your</TextRow>
						<TextRow>Business</TextRow>
					</>
				) : (
					<TextRow>Elevate Your Business</TextRow>
				)}

				<HeroButton>Book an appointment</HeroButton>
			</SectionHeading>
			<ShippedContainer>
				<span>
					Recently shipped products <img src={shippedArrow} alt="" />
				</span>
				<img src={shippedProducts} alt={"icon"} />
			</ShippedContainer>
		</Section>
	);
};

export default HeroSection;
