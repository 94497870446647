import {
	Section,
	SectionHeading,
	TextRow,
	PinkBall,
	PinkCube,
	TrustedContainer,
	TrustedParagraph,
	TrustedIcons
} from "./styles"
import pinkBall from "../../../../Assets/Images/Home/pink-ball.webp";
import pinkCube from "../../../../Assets/Images/Home/pink-cube.webp";
import pinkCubeMobile from "../../../../Assets/Images/Home/pink-cube-mobile.webp";
import trustedIcons from "../../../../Assets/Images/Home/trusted-icons.webp"
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useEffect, useRef} from "react";
import {useMobileDetector} from "../../../../utils/customHooks";

gsap.registerPlugin(ScrollTrigger);

const HeroSection = () => {
	const cubRef = useRef(null);
	const sectionRef = useRef(null);
	const isMobile = useMobileDetector();

	useEffect(() => {
		gsap.to(cubRef.current, {
			rotation: 180,
			y: "45%",
			x: -400,
			ease: "none",
			scrollTrigger: {
				trigger: sectionRef.current,
				start: "top top",
				end: "bottom top",
				scrub: true,
			},
		});
	}, []);
	return(
		<Section ref={sectionRef}>
			<SectionHeading>
				<TextRow>
					We are digital
				</TextRow>
				<TextRow>
					<PinkBall src={pinkBall} alt={'icon'}/>
					product
					{isMobile ?
						<PinkCube src={pinkCubeMobile} alt={'icon'} ref={cubRef}/>
						:
						<PinkCube src={pinkCube} alt={'icon'} ref={cubRef}/>
					}

				</TextRow>
				<TextRow>
					builders
				</TextRow>
			</SectionHeading>
			<TrustedContainer>
				<TrustedParagraph>
					Out staffing of IT-specialists, strengthening<br/>
					teams and projects
				</TrustedParagraph>
				<TrustedIcons>
					<span>
						Trusted by many:
					</span>
					<img src={trustedIcons} alt={'icon'}/>
				</TrustedIcons>
			</TrustedContainer>
		</Section>
	);
}

export default HeroSection;