import HeroSection from "../../Components/Organisms/Culture/HeroSection";
import ExcellenceSection from "../../Components/Organisms/Culture/ExcellenceSection";
import OurDigitalHeaven from "../../Components/Organisms/Culture/OurDigitalHeaven";
import WorkProcess from "../../Components/Organisms/Home/WorkProcess";
import Reputation from "../../Components/Organisms/Home/Reputation";
import Questions from "../../Components/Organisms/Home/Questions";

const CulturePage = () => {
	return (
		<div>
			<HeroSection />
			<OurDigitalHeaven />
			<ExcellenceSection />
			<WorkProcess />
			<Reputation />
			<Questions />
		</div>
	);
};

export default CulturePage;
