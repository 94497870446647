import styled from "styled-components";

export const Section = styled.section`
	min-height: 690px;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	max-width: 1400px;
	margin: 0 auto;
	position: relative;
	
	@media (min-width: 996px) and (max-width: 1200px) {
		height: 80vh;
	}

	@media (min-width: 768px) and (max-width: 996px) {
		height: 70vh;
	}

	@media (max-width: 768px) {
		width: 100%;
		margin-left: 0;
	}
`;

export const SectionImage = styled.img`
	position: absolute;
	@media screen and (min-width: 1300px) and (max-width: 1600px) {
		&:nth-child(1) {
			width: 126px;
		}

		width: 190px;
	}
	@media screen and (min-width: 1100px) and (max-width: 1300px) {
		&:nth-child(1) {
			width: 106px;
		}

		width: 170px;
	}
	@media screen and (min-width: 768px) and (max-width: 1100px) {
		&:nth-child(1) {
			width: 86px;
		}

		width: 130px;
	}

	@media screen and (max-width: 768px) {
		&:nth-child(1) {
			width: 59px;
		}

		width: 76px;
	}
`;


export const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	align-items: center;
`;

export const SectionHeading = styled.h3`
	margin: 0;
	font-size: calc(15px + 2.063vw);
	font-weight: 500;
	line-height: calc(18px + 2.506vw);
	text-align: center;
	color: #000000;
	text-transform: uppercase;

	@media screen and (max-width: 768px) {
		font-size: 24px;
		line-height: 29px;
	}
`;

export const SectionParagraph = styled.p`
	margin: 20px 0 0;
	max-width: 520px;
	font-size: calc(10px + 0.625vw);
	font-weight: 400;
	line-height: calc(10px + 1.387vw);
	letter-spacing: 0.02em;
	text-align: center;
	color: #000000;
	
	@media (min-width: 768px) and (max-width: 1100px) {
		max-width: 350px;
	}

	@media screen and (max-width: 768px) {
		font-size: 12px;
		line-height: 14px;
		max-width: 270px;
	}
`;
