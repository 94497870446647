import styled from "styled-components";
import { Link } from "react-router-dom";

export const Navbar = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 26px 0;
    z-index: 111;
    position: relative;
    max-width: 1400px;
    left: 0;
    right: 0;
    margin: 0 auto;

    & > div:first-child {
        display: flex;
        align-items: center;
        gap: 20px;
    }
`;

export const NavLinkMobile = styled.div`
    display: none;

    @media (max-width: 996px) {
        width: 20px;
        height: 12px;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        div, &:before, &:after {
            content: '';
            width: 100%;
            border: 1px solid #1F1F1F;
        }
    }
`

export const NavLinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: calc(2px + 1.875vw);

    @media (max-width: 992px) {
        display: none;
    }

    @media (min-width: 1600px) {
        gap: 32px;
    }
`;

export const Logo = styled.img`
	@media (max-width: 996px) {
		height: 32px !important;
	}
`
export const NavLink = styled(Link)`
    font-size: calc(10px + 0.625vw);
    font-weight: 400;
    line-height: calc(12px + 0.762vw);
    letter-spacing: 0.02em;
    text-align: left;
    color: #7f7f7f;
    text-decoration: none;
    display: flex;
    align-items: center;
	
    @media (min-width: 1600px) {
        font-size: 20px;
        line-height: 24.2px;
    }
`;

export const ContactLink = styled(Link)`
	font-size: calc(10px + 0.625vw);
	font-weight: 400;
	line-height: calc(12px + 0.762vw);
	letter-spacing: 0.02em;
	color: #000000;

    @media (min-width: 1600px) {
        font-size: 20px;
        line-height: 24.2px;
    }
`;
