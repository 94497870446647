import styled from "styled-components";
import { SwiperSlide } from "swiper/react";

export const Section = styled.section`
	flex: 1;
	min-height: 600px;
	width: calc(100% + 100px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;
	padding-top: 60px;
	margin: 0 auto;
`;

export const SectionHeading = styled.h1`
    font-size: 96px;
	font-weight: 500;
    line-height: 116px;
	text-align: left;
	display: flex;
	flex-direction: column;
	text-transform: uppercase;
	white-space: nowrap;
	margin: 0 auto 120px;
	width: min-content;
	
	@media (min-width: 1200px) and (max-width: 1400px) {
		font-size: 83px;
		line-height: 106px;
		margin: 0 auto 100px;
	}

	@media (min-width: 1100px) and (max-width: 1200px) {
		font-size: 74px;
		line-height: 96px;
		margin: 0 auto 80px;
	}

	@media (min-width: 992px) and (max-width: 1100px) {
		font-size: 63px;
		line-height: 96px;
		margin: 0 auto 60px;
	}
	
	@media (min-width: 480px) and (max-width: 992px) {
		font-size: calc(10px + 5.343vw);
		line-height: calc(14px + 8.266vw);
        margin: 0;
	}

    @media (max-width: 480px) {
        font-size: calc(4px + 5.343vw);
        line-height: calc(6px + 8.266vw);
        margin: 0;
    }
`;

export const TextRow = styled.span`
	width: min-content;
	display: block;
	&:nth-child(1) {
		margin-left: 140px;
	}
	&:nth-child(2) {
		margin-top: -30px;
	}
	
	@media (min-width: 480px) and (max-width: 768px) {
        &:nth-child(1) {
            margin-left: 70px;
        }
        &:nth-child(2) {
            margin-top: -20px;
        }
	}

    @media (max-width: 480px) {
        &:nth-child(1) {
            margin-left: 40px;
        }
        &:nth-child(2) {
            margin-top: -10px;
        }
    }
`;

export const CustomSwiperSlide = styled(SwiperSlide)`
    max-width: ${(props) => (props.type === "small" ? "680px" : "934px")};

    @media (min-width: 1400px) and (max-width: 1600px) {
        max-width: ${(props) => (props.type === "small" ? "544px" : "747.2px")};
    }
    @media (min-width: 1200px) and (max-width: 1400px) {
        max-width: ${(props) => (props.type === "small" ? "408px" : "560.4px")};
    }
    @media (min-width: 480px) and (max-width: 1200px) {
        max-width: ${(props) => (props.type === "small" ? "272px" : "373.6px")};
    }
    @media (max-width: 480px) {
        max-width: ${(props) => (props.type === "small" ? "204px" : "280.2px")};
    }
`;

export const SliderImage = styled.img`
	width: 100%;
	display: block;
	height: auto;
	box-sizing: border-box;
`;
