import { Contacts, ContactsContainer, Section, SectionHeading } from "./styles";

const ContactsSection = () => {
	return (
		<Section>
			<SectionHeading>
				Contact us
				<span>&nbsp;&nbsp;&nbsp;C</span>
			</SectionHeading>
			<ContactsContainer>
				<Contacts>
					<h6>Visit us</h6>
					<p>
						Yeraz Residential Community 4/3 Nikoghayos Adonts Street
					</p>
					<a href="https://maps.app.goo.gl/FyNeKPVEtG27pSPB7">
						Get Directions
					</a>
				</Contacts>
				<Contacts>
					<h6>Email us</h6>
					<p>General</p>
					<a href="mailto:info@spesna.com">info@spesna.com</a>
				</Contacts>
				<Contacts>
					<h6>Call us</h6>
					<p>Phone</p>
					<a href="tel:+37477733373">+374 77 73 33 73</a>
				</Contacts>
			</ContactsContainer>
		</Section>
	);
};

export default ContactsSection;
