import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	left: 0;
	right: 0;
	max-width: 1400px;
	margin: 140px auto 0 auto;
`;

export const SectionHeading = styled.h1`
	display: flex;
	flex-direction: column;
`;

export const TextRow = styled.span`
	font-size: calc(24px + 4vw);
	font-weight: 500;
	line-height: calc(24px + 4vw);
	text-align: left;
	text-transform: uppercase;

	&:first-child {
		margin-left: calc(30px + 3.688vw);
	}

    @media (min-width: 1200px) and (max-width: 1400px) {
        font-size: calc(24px + 3.5vw);
        line-height: calc(24px + 3.5vw);
    }
	
	@media (min-width: 992px) and (max-width: 1200px) {
		font-size: calc(20px + 4vw);
		line-height: calc(20px + 4vw);
	}

    @media (min-width: 768px) and (max-width: 992px) {
        font-size: calc(20px + 3vw);
        line-height: calc(20px + 3vw);
    }

    @media (max-width: 768px) {
        font-size: calc(18px + 3vw);
        line-height: calc(18px + 3vw);
		
		&:first-child {
			margin-left: 0;
		}
		&:nth-child(2) {
			margin-left: 52px;
		}
        &:nth-child(3) {
            margin-left: 6px;
        }
    }

    @media (max-width: 480px) {
        font-size: 32px;
        line-height: 38.73px;
    }
	
	@media (min-width: 1600px) {
		font-size: 96px;
		line-height: 96px;

		&:first-child {
			margin-left: 89px;
		}
	}
`;

export const FonderContainer = styled.div`
	margin: 80px auto 0;
	width: 100%;
	box-sizing: border-box;
	padding: calc(20px + 3.75vw);

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: #fafafa;
	border-radius: 80px;
	
	@media (max-width: 480px) {
        border-radius: 20px;
		padding: 30px 20px;
	}
	
	@media (min-width: 1600px) {
		padding: 80px;
	}
`;

export const FonderReviewBox = styled.div`
	width: 720px;
	p {
		font-size: calc(8px + 1vw);
		font-weight: 400;
		line-height: calc(8px + 1.375vw);
		letter-spacing: 0.02em;
	}
	
	@media (max-width: 480px) {
		p {
            font-size: 12px;
            line-height: 14.52px;
        }
    }

	@media screen and (min-width: 1600px) {
		p {
			font-size: 24px;
			line-height: 29.05px;
		}
	}
`;

export const FonderAvatarContainer = styled.div`
	display: flex;
	gap: 16px;
`;
export const FounderAvatar = styled.img`
	width: 60px;
	height: 60px;
	
	@media (max-width: 480px) {
		width: 30px;
		height: 30px;
	}
`;

export const FonderNameContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;

	h5 {
		margin: 0;
		font-size: 26px;
		font-weight: 500;
		line-height: 31.47px;
	}
	h6 {
		margin: 0;
		font-size: 20px;
		font-weight: 400;
		line-height: 24.2px;
	}
	
	@media (max-width: 480px) {
		h5 {
            font-size: 12px;
            line-height: 14.52px;
        }
		h6 {
            font-size: 10px;
            line-height: 12.1px;
        }
	}
`;
