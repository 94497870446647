import {Section} from "./styles";
import {useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import {
	MainContainer,
	SectionHeading,
	SectionImage,
	SectionParagraph,
} from "./styles";

import firstImage from "../../../../Assets/Images/Home/focusArea1.png";
import secondImage from "../../../../Assets/Images/Home/focusArea2.png";
import thirdImage from "../../../../Assets/Images/Home/focusArea3.png";
import fourthImage from "../../../../Assets/Images/Home/focusArea4.png";

gsap.registerPlugin(ScrollTrigger);

const FocusArea = () => {
	const elementsRef = useRef([]);
	const sectionRef = useRef(null);

	useEffect(() => {
		const mm = gsap.matchMedia(); // Initialize matchMedia

		mm.add("(min-width: 768px)", () => {
			// Animation for screens larger than or equal to 768px
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: sectionRef.current,
					start: "top bottom",
					end: "bottom bottom",
					scrub: 5,
					// markers: true,
				}
			});

			tl.to(elementsRef.current[0], {x: "-25vw", y: "-80%", ease: "power1.out", duration: 6}, 0)
				.to(elementsRef.current[1], {x: "-30vw", y: "80%", ease: "power1.out", duration: 6}, 0)
				.to(elementsRef.current[2], {x: "35vw", y: "-75%", ease: "power1.out", duration: 6}, 0)
				.to(elementsRef.current[3], {x: "30vw", y: "85%", ease: "power1.out", duration: 6}, 0);
		});

		mm.add("(max-width: 767px)", () => {
			// Animation for screens smaller than 768px
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: sectionRef.current,
					start: "top bottom",
					end: "bottom bottom",
					scrub: 5,
					// markers: true,
				}
			});

			tl.to(elementsRef.current[0], {x: "-25vw", y: "-150%", ease: "power1.out", duration: 6}, 0)
				.to(elementsRef.current[1], {x: "-30vw", y: "140%", ease: "power1.out", duration: 6}, 0)
				.to(elementsRef.current[2], {x: "30vw", y: "-140%", ease: "power1.out", duration: 6}, 0)
				.to(elementsRef.current[3], {x: "25vw", y: "150%", ease: "power1.out", duration: 6}, 0);
		});

		return () => mm.revert(); // Cleanup matchMedia instances
	}, []);

	return (
		<Section ref={sectionRef}>
			<SectionImage src={firstImage} alt="icon" ref={(el) => (elementsRef.current[0] = el)}/>
			<SectionImage src={secondImage} alt="icon" ref={(el) => (elementsRef.current[1] = el)}/>
			<MainContainer>
				<SectionHeading>
					Spesna’s
					<br/>
					focus area
				</SectionHeading>
				<SectionParagraph>
					We specialize in driving digital growth through our unique operations
					model and domain expertise, ensuring transformative business growth.
				</SectionParagraph>
			</MainContainer>

				<SectionImage src={thirdImage} alt="icon" ref={(el) => (elementsRef.current[2] = el)}/>
				<SectionImage src={fourthImage} alt="icon" ref={(el) => (elementsRef.current[3] = el)}/>
		</Section>
	);
};

export default FocusArea;
