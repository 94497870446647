import {
	FooterSection,
	FirstColumn,
	SecondColumn,
	CompanyInfo,
	TextRow,
	PrivacyPolicy,
	BusinessIdea,
	SocialLinks,
	Copyright,
	PageLinks,
} from "./styles";
import logoSmallImg from "../../../Assets/Images/logoSmall.webp";
import footerArrow from "../../../Assets/Images/Home/footer-arrow.svg";
import { useMobileDetector } from "../../../utils/customHooks";

const DefaultFooter = () => {
	const isMobile = useMobileDetector();

	return (
		<FooterSection>
			<div>
				<div>
					<CompanyInfo>
						<img src={logoSmallImg} alt="" />
						<div>
							<TextRow>Yeraz Residential Community</TextRow>
							<TextRow>4/3 Nikoghayos Adonts Street</TextRow>
							<TextRow>
								info@spesna.com{" "}
								<img src={footerArrow} alt={""} />
							</TextRow>
						</div>
					</CompanyInfo>
				</div>

				<div>
					<BusinessIdea>
						<div>
							<TextRow>Have a great business idea?</TextRow>
						</div>
						<div>
							<TextRow>
								LETS TALK <img src={footerArrow} alt={""} />
							</TextRow>
						</div>
					</BusinessIdea>
					<PageLinks>
						<TextRow>Services</TextRow>
						<TextRow>Work</TextRow>
						<TextRow>Culture</TextRow>
						<TextRow>Contact</TextRow>
						<TextRow>Careers</TextRow>
					</PageLinks>

					{!isMobile ? (
						<SocialLinks>
							<TextRow>LinkedIn</TextRow>
							<TextRow>Behance</TextRow>
							<TextRow>Instagram</TextRow>
						</SocialLinks>
					) : (
						<PrivacyPolicy>
							<TextRow>Privacy Policy</TextRow>
							<TextRow>Terms of Services</TextRow>
						</PrivacyPolicy>
					)}
				</div>

				<div>
					{!isMobile ? (
						<PrivacyPolicy>
							<TextRow>Privacy Policy</TextRow>
							<TextRow>Terms of Services</TextRow>
						</PrivacyPolicy>
					) : (
						<SocialLinks>
							<TextRow>LinkedIn</TextRow>
							<TextRow>Behance</TextRow>
							<TextRow>Instagram</TextRow>
						</SocialLinks>
					)}
				</div>

				<div>
					<Copyright>
						<TextRow>© 2024 Spesna. All rights reserved</TextRow>
					</Copyright>
				</div>
			</div>
		</FooterSection>
	);
};

export default DefaultFooter;
