import styled from "styled-components";

export const Section = styled.section`
	min-height: 600px;
	width: 100%;
	box-sizing: border-box;
	max-width: 1400px;
	margin: 140px auto 0 auto;
`;

export const SectionHeading = styled.h3`
	position: relative;
	float: left;
	font-size: calc(24px + 4.5vw);
	font-weight: 500;
	line-height: calc(24px + 5.73vw);
	text-align: center;
	margin: 0 0 80px;
	color: #000000;
	text-transform: uppercase;

	@media (min-width: 1600px) {
		font-size: 96px;
		line-height: 116.18px;
	}
	@media (max-width: 768px) {
		margin: 0 0 40px;
	}
`;

export const StepsWrapper = styled.div`
	display: flex;
	gap: calc(10px + 0.625vw);
	margin-top: calc(20px + 3.75vw);
	width: 100%;

	&:nth-of-type(1) {
		justify-content: end;
	}
	&:last-child {
		justify-content: start;
	}

    @media (max-width: 996px) {
        &:nth-of-type(1) {
            justify-content: center;
        }
        &:last-child {
            justify-content: center;
        }
    }
	
	@media (min-width: 1600px) {
		gap: 20px;
		margin-top: 80px;
	}

`;

export const Step = styled.div`
	display: flex;
	flex-direction: column;
	gap: calc(12px + 1.25vw);

	& > div:first-child {
		display: flex;
		gap: calc(10px + 0.625vw);
		align-items: center;
	}

	& > div:first-child hr {
		width: 100%;
		border: none;
		height: 1px;
		background-color: #f3f3f3;
	}

	@media (min-width: 1600px) {
        gap: 32px;
		
		& > div:first-child {
			gap: 20px;
		}
	}
`;

export const StepLabel = styled.div`
	display: block;
	box-sizing: border-box;
	width: min-content;
	padding: 10px 28px;
	border-radius: 90px;
	border: 1px solid #000000;
	font-size: calc(10px + 0.625vw);
	font-weight: 500;
	line-height: calc(10px + 0.887vw);
	letter-spacing: 0.02em;
	background: ${(props) => (props.current ? "#000" : "#fff")};
	color: ${(props) => (props.current ? "#fff" : "#000")};
	white-space: nowrap;

	@media (min-width: 1600px) {
		font-size: 20px;
		line-height: 24.2px;
	}
`;

export const StepContainer = styled.div`
	position: relative;
	display: flex;
	align-items: end;

	background-image: ${(props) =>
		props.background && `url(${props.background})`};
	width: 452px;
	height: 600px;
	border-radius: 30px;
	background-size: 100%;
	background-repeat: no-repeat;
	background-color: #252628;
	background-position: center;
	overflow: hidden;

	div {
		position: absolute;
		bottom: -100%;
		left: calc(10px + 1.875vw);
		right: calc(10px + 1.875vw);
		margin: 0 auto;
		color: #fff;
		visibility: hidden;
	}

	&:hover div {
		transition: 0.5s ease-in-out;
		bottom: calc(10px + 1.875vw);
		visibility: visible;
	}

	div span {
		font-family: Inter, sans-serif;
		font-size: calc(12px + 0.75vw);
		font-weight: 500;
		line-height: calc(14px + 0.941vw);
		letter-spacing: 0.02em;
		text-align: left;
	}

	div p {
		font-family: Inter, sans-serif;
		font-size: calc(10px + 0.625vw);
		font-weight: 400;
		line-height: calc(12px + 0.762vw);
		letter-spacing: 0.02em;
		text-align: left;
	}

	@media (min-width: 996px) and (max-width: 1200px) {
		width: 406.8px;
		height: 540px;
	}

	@media (min-width: 768px) and (max-width: 996px) {
		width: 316.4px;
		height: 420px;
	}

	@media (max-width: 768px) {
		width: 43.75vw;
		height: 71.25vw;
	}

	@media (min-width: 1600px) {
		div {
			left: 40px;
			right: 40px;
		}

		&:hover div {
			bottom: 40px;
		}

		div span {
			font-size: 24px;
			line-height: 29.05px;
		}
	}
`;
