import styled from "styled-components";

export const Section = styled.section`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top: 60px;
    max-width: 1250px;
    margin: 0 auto;

    @media (max-width: 1400px) {
        height: 100%;
    }
`;

export const SectionHeading = styled.h1`
    font-size: calc(24px + 4.5vw);
    font-weight: 500;
    line-height: calc(32px + 5.261vw);
    text-align: left;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    white-space: nowrap;
    margin: 0 auto;
	width: 100%;
	
	@media (min-width: 480px) and (max-width: 992px) {
		justify-content: center;
		align-items: center;
    }
	
	@media (min-width: 320px) and (max-width: 480px) {
        font-size: calc(18px + 4.5vw);
        line-height: calc(26px + 5.261vw);
	}

    @media (max-width: 320px) {
        font-size: 32px;
        line-height: 38.73px;
    }

    @media (min-width: 1600px) {
        font-size: 96px;
        line-height: 116.18px;
    }
`;

export const TextRow = styled.span`
	
	&:first-child {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
		position: relative;
	}

    &:nth-child(2) {
		display: flex;
		justify-content: flex-end;
        margin: calc(-10px - 0.625vw) 0 0 0;
        z-index: -2;
    }

    &:nth-child(3) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: calc(-10px - 0.625vw) 0 0 calc(20px + 1.25vw);
    }

    @media (max-width: 768px) {
        &:first-child {
            margin-left: calc(-10px - 7.813vw);
        }

        &:nth-child(2) {
			justify-content: center;
			z-index: 0;
			margin-left: calc(5px + 7.161vw);
        }

        &:nth-child(3) {
			margin: calc(-10px - 0.625vw) 0 0 calc(-40px - 23.438vw);
			z-index: -2;
        }

        &:nth-child(4) {
            margin: calc(-10px - 0.625vw) 0 0 -1.302vw;
        }

        &:nth-child(5) {
			margin: calc(-10px - 0.625vw) 0 0 calc(5px + 4.557vw);
        }
    }

    @media (max-width: 320px) {
		margin-top: 0 !important;
    }

    @media (min-width: 1600px) {
		&:first-child {
            margin-left: 0;
		}
        &:nth-child(2) {
            margin: -20px 0 0 0;
        }

        &:nth-child(3) {
            margin: -20px 0 0 120px;
        }
    }
`;

export const RoundedShape = styled.img`
    position: absolute;
    width: calc(175px + 50vw);
    height: calc(100px + 50vw);
	left: calc(-20px - 10vw);
	top: calc(-20px - 10vw);
    z-index: -1;

    @media (max-width: 320px) {
        width: 380px;
        height: 380px;
		left: -90px;
		top: -40px;
    }
	

    @media (min-width: 1600px) {
        width: 975px;
        height: 900px;
        left: -180px;
        top: -180px;
    }
`;

export const HeroButton = styled("button")`
	cursor: pointer;
	margin-top: 3.75vw;
	padding: 16px 28px;
	border-radius: 90px;
	background: #000000;
	font-size: calc(10px + 1.125vw);
	font-weight: 500;
	line-height: calc(10px + 1.493vw);
	letter-spacing: 0.02em;
	color: #ffffff;
	z-index: 3;
	border: none;

	@media (max-width: 320px) {
		width: 100%;
		font-size: 16px;
		line-height: 19.36px;
		margin-top: 50px;
	}

	@media (min-width: 1600px) {
		font-size: 28px;
		line-height: 33.89px;
		margin-top: 80px;
	}
`;
