import {
	Navbar,
	NavLinkMobile,
	NavLinkContainer,
	NavLink,
	ContactLink, Logo,
} from "./styles";
import logoImg from "../../../Assets/Images/logo.webp";
import { useMobileDetector } from "../../../utils/customHooks";

const DefaultNavbar = () => {

	return (
		<Navbar>
			<div>
				<NavLinkMobile>
					<div></div>
				</NavLinkMobile>

				<NavLink to={"/"}>
					<Logo src={logoImg} alt={"Spesna"} />
				</NavLink>
			</div>

			{/*{!isMobile && (*/}
			<NavLinkContainer>
				<NavLink to={"/services"}>Services</NavLink>
				<NavLink to={"/work"}>Work</NavLink>
				<NavLink to={"/culture"}>Culture</NavLink>
				<NavLink to={"/contact-us"}>Contact</NavLink>
				<NavLink to={"/"}>Careers</NavLink>
			</NavLinkContainer>
			{/*)}*/}
			<ContactLink to={"/"}>Let’s Talk</ContactLink>
		</Navbar>
	);
};

export default DefaultNavbar;
