import { Section, WorkContainer } from "./styles";

import allSuggested1 from "../../../../Assets/Images/Work/allSuggested1.webp";
import allSuggested2 from "../../../../Assets/Images/Work/allSuggested2.webp";
import allSuggested3 from "../../../../Assets/Images/Work/allSuggested3.webp";
import allSuggested4 from "../../../../Assets/Images/Work/allSuggested4.webp";
import allSuggested5 from "../../../../Assets/Images/Work/allSuggested5.webp";
import allSuggested6 from "../../../../Assets/Images/Work/allSuggested6.webp";
import allSuggested7 from "../../../../Assets/Images/Work/allSuggested7.webp";
import allSuggested10 from "../../../../Assets/Images/Work/allSuggested10.webp";

const suggestedWork = [
	{
		img: allSuggested1,
		title: "Quality Assurance ",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Venhub",
		type: "small",
	},
	{
		img: allSuggested2,
		title: "Web development",
		description: "Venhub: The future of retail",
		projectName: "Venhub",
		type: "small",
	},

	{
		img: allSuggested3,
		title: "IOS/Android development",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Venhub",
		type: "large",
	},

	{
		img: allSuggested4,
		title: "Desktop app development",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Venhub",
		type: "small",
	},
	{
		img: allSuggested5,
		title: "MVP development services",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Venhub",
		type: "small",
	},

	{
		img: allSuggested6,
		title: "UI/UX design",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Venhub",
		type: "large",
	},

	{
		img: allSuggested7,
		title: "Graphic design",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Venhub",
		type: "small",
	},
	{
		img: allSuggested1,
		title: "Project Management",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Venhub",
		type: "small",
	},

	{
		img: allSuggested3,
		title: "IT outsourcing",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Venhub",
		type: "large",
	},

	{
		img: allSuggested10,
		title: "IT out-staffing",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Venhub",
		type: "large",
	},
];

const AllSuggested = ({ handleOpenPopup }) => {
	return (
		<Section>
			{suggestedWork.map((work, index) => (
				<WorkContainer
					type={work.type}
					key={index}
					onClick={() => {
						handleOpenPopup(true, work.projectName);
					}}
				>
					<img src={work.img} alt={work.title} />
					<div>
						<span>{work.title}</span>
						<p>{work.description}</p>
					</div>
				</WorkContainer>
			))}
		</Section>
	);
};

export default AllSuggested;
