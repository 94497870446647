import { Outlet } from "react-router-dom";
import DefaultNavbar from "./Navbar";
import { OutletContainer } from "./styles";
import DefaultFooter from "./Footer";

const DefaultLayout = () => {
	return (
		<OutletContainer>
			<DefaultNavbar />
			<Outlet />
			<DefaultFooter />
		</OutletContainer>
	);
};

export default DefaultLayout;
