import {
	Section,
	SectionHeading,
	TextRow,
	RoundedShape,
	HeroButton,
	ShippedContainer,
} from "./styles";

import roundedShapeImg from "../../../../Assets/Images/Work/roundedShape.webp";

import { useMobileDetector } from "../../../../utils/customHooks";

const HeroSection = () => {
	const isMobile = useMobileDetector();

	return (
		<Section>
			<SectionHeading>
				{isMobile ? (
					<>
						<TextRow>
							Creating{" "}
							<RoundedShape src={roundedShapeImg} alt="" />
						</TextRow>
						<TextRow>impactful</TextRow>
						<TextRow>digital</TextRow>
						<TextRow>solutions for</TextRow>
						<TextRow>top businesses</TextRow>
					</>
				) : (
					<>
						<TextRow>
							Creating impactful{" "}
							<RoundedShape src={roundedShapeImg} alt="" />
						</TextRow>
						<TextRow>digital solutions</TextRow>
						<TextRow>for top businesses</TextRow>
					</>
				)}

				<HeroButton>Book an appointment</HeroButton>
			</SectionHeading>
		</Section>
	);
};

export default HeroSection;
