import { Section, SectionHeading, TextRow, ShareWrapper } from "./styles";
import { useMobileDetector } from "../../../../utils/customHooks";

const Share = () => {
	const isMobile = useMobileDetector();

	return (
		<Section>
			<SectionHeading>
				{isMobile ? (
					<>
						<TextRow>Share your</TextRow>
						<TextRow>vision</TextRow>
						<TextRow>we'll plan</TextRow>
						<TextRow>the next step</TextRow>
						<TextRow>together</TextRow>
					</>
				) : (
					<>
						<TextRow>Share your vision</TextRow>
						<TextRow>we'll plan</TextRow>
						<TextRow>the next step together</TextRow>
					</>
				)}
			</SectionHeading>

			<ShareWrapper>
				<form action="#">
					<input type="text" placeholder="Name*" />
					<input type="text" placeholder="Email*" />
					<input
						type="text"
						placeholder="Describe your needs in details*"
					/>
					<button>Send</button>
				</form>
			</ShareWrapper>
		</Section>
	);
};

export default Share;
