import styled from "styled-components";

export const PopupAbout = styled.div`
	margin-top: 110px;
	display: flex;
	justify-content: space-between;

	div:first-child {
		width: 16.7142857%;
	}

	div:first-child span {
		font-family: Inter, sans-serif;
		font-size: calc(10px + 0.625vw);
		font-weight: 400;
		line-height: calc(12px + 0.762vw);
		text-align: left;
		text-transform: uppercase;
		display: flex;
		gap: calc(10px + 0.625vw);
	}

	div:first-child span::before {
		content: "";
		display: inline-block;
		width: 20px;
		height: 20px;
		background-color: rgba(0, 0, 0, 1);
		border-radius: 50%;
	}

	div:nth-child(2) {
		width: 83.2857143%;
	}

	div:nth-child(2) p {
		font-family: Inter, sans-serif;
		font-size: calc(24px + 1.5vw);
		font-weight: 400;
		line-height: calc(32px + 1.631vw);
		text-align: left;
		color: rgba(0, 0, 0, 1);
		margin: 0;
	}

	div:nth-child(2) > p:first-child {
		margin-left: calc(10px + 13.938vw);
	}

    @media (max-width: 1500px) {
        div:nth-child(2) > p:first-child {
            margin-left: 0;
        }
    }

	@media (max-width: 1200px) {
		flex-direction: column;
		div:first-child {
			width: 100%;
		}

		div:nth-child(2) {
			width: 100%;
			margin-top: 0;
		}
	}

	@media (max-width: 320px) {
		margin-top: 40px;
		gap: 24px;
		
		div:first-child span {
            font-size: 12px;
            line-height: 14.52px;
			gap: 10px;
        }

        div:first-child span::before {
			width: 14px;
			height: 14px;
		}
		
		p {
            font-size: 20px !important;
            line-height: 24.2px !important;
        }
	}

	@media (min-width: 1600px) {
		div:first-child span {
			font-size: 20px;
			line-height: 24.2px;
			gap: 20px;
		}

		div:nth-child(2) {
			width: 83.2857143%;
		}

		div:nth-child(2) p {
			font-size: 48px;
			line-height: 58.09px;
		}

		div:nth-child(2) > p:first-child {
			margin-left: 233px;
		}
	}
`;
