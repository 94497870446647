import {useMobileDetector} from "../../utils/customHooks";
import HeroSection from "../../Components/Organisms/Home/HeroSection";
import FocusArea from "../../Components/Organisms/Home/FocusArea";
import WorkProcess from "../../Components/Organisms/Home/WorkProcess";
import GrowthSection from "../../Components/Organisms/Home/GrowthSection";
import SelectedCases from "../../Components/Organisms/Home/SelectedCases";
import Reputation from "../../Components/Organisms/Home/Reputation";
import Questions from "../../Components/Organisms/Home/Questions";

const HomePage = () => {
  return (
    <div>
      <HeroSection />
      <FocusArea />
      <GrowthSection />
      <SelectedCases />
      <WorkProcess />
      <Reputation />
      <Questions />
    </div>
  );
};

export default HomePage;
