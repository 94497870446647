import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	left: 0;
	right: 0;
	max-width: 1400px;
	margin: 0 auto;
`;

export const SectionHeading = styled.h1`
	position: relative;
	top: 0;
`;

export const TextRow = styled.span`
	font-family: Inter, sans-serif;
	font-weight: 500;
	font-size: calc(15px + 5.063vw);
	line-height: calc(24px + 5.761vw);
	display: block;
	text-transform: uppercase;

	&:nth-of-type(1) {
		margin-left: 80px;
	}

	&:nth-of-type(2) {
		margin-top: -25px;
	}

	@media (min-width: 768px) and (max-width: 996px) {
		&:nth-of-type(1) {
			margin-left: 70px;
		}
		&:nth-of-type(2) {
			margin-top: -20px;
		}
	}

	@media (max-width: 768px) {
		&:nth-of-type(1) {
			margin-left: 50px;
		}
		&:nth-of-type(2) {
			margin-top: -10px;
		}
	}

	@media (min-width: 1600px) {
		font-size: 96px;
		line-height: 116.18px;
	}
`;

export const ProductRow = styled.div`
	display: flex;
	width: 100%;
	gap: calc(10px + 0.625vw);
	margin-top: calc(30px + 1.875vw);

	@media (max-width: 996px) {
		flex-direction: column;
	}

	@media (min-width: 1600px) {
		gap: 20px;
		margin-top: 24px;
	}
`;

export const ProductContainer = styled.div`
	width: ${(props) =>
		props.size === "large" ? "66.95652174%" : "33.04347826%"};
	background-color: #fafafa;
	border-radius: 30px;
	padding: calc(20px + 1.25vw);
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	box-sizing: border-box;

	@media (max-width: 996px) {
		width: 100%;
	}

	@media (min-width: 1600px) {
		padding: 40px;
	}
`;

export const ProductInfo = styled.div`
	display: ${(props) =>
		props.size === "large"
			? "flex"
			: props.size === "small"
				? "block"
				: ""};
	justify-content: ${(props) =>
		props.size === "large"
			? "space-between"
			: props.size === "small"
				? ""
				: ""};
	align-items: ${(props) =>
		props.size === "large" ? "start" : props.size === "small" ? "" : ""};
	width: 100%;
`;

export const ProductTitle = styled.span`
	font-size: calc(10px + 0.875vw);
	font-weight: 500;
	line-height: calc(10px + 1.191vw);
	letter-spacing: 0.02em;

	@media (min-width: 1600px) {
		font-size: 24px;
		line-height: 29.05px;
	}
`;

export const ProductDescription = styled.p`
	font-size: calc(5px + 0.938vw);
	font-weight: 400;
	line-height: calc(10px + 0.887vw);
	letter-spacing: 0.02em;
	margin-top: calc(5px + 0.313vw);
	max-width: 440px;

	@media (min-width: 1600px) {
		font-size: 20px;
		line-height: 24.2px;
	}
`;

export const ProductButton = styled.button`
	border-radius: 90px;
	padding: calc(5px + 0.313vw) calc(10px + 1.125vw) calc(5px + 0.313vw)
		calc(10px + 1.125vw);
	display: block;
	font-size: calc(5px + 0.938vw);

	@media (min-width: 1600px) {
		padding: 10px 28px 10px 28px;
		gap: 10px;
		font-size: 20px;
		line-height: 24.2px;
	}
`;

export const ProductImages = styled.div`
	width: ${(props) => (props.size === "small" ? "94%" : "100%")};
	display: flex;
	justify-content: space-between;
	gap: calc(10px + 0.625vw);

	div:nth-child(2) {
		width: ${(props) => props.background && "46.07%"};
	}

	@media (min-width: 1600px) {
		gap: 20px;
	}
`;

export const RectangleBackground = styled.div`
	width: 52.2%;
	background-color: #f2f2f2;
	border-radius: 30px;
	display: flex;
	justify-content: center;

	img {
		width: 88.34%;
	}
`;

export const ProductImage = styled.img`
	width: 100%;
	border-radius: 1.875vw;
	height: auto;

	@media (min-width: 1600px) {
		height: 233px;
	}
`;
