import AllSuggested from "../../Components/Organisms/Services/AllSuggested";
import Steps from "../../Components/Organisms/Services/Steps";
import HeroSection from "../../Components/Organisms/Services/HeroSection";
import Share from "../../Components/Organisms/Services/Share";
import Snapshot from "../../Components/Organisms/Services/Snapshot";

const ServicesPage = () => {
	return (
		<div>
			<HeroSection />
			<Snapshot />
			<AllSuggested />
			<Steps />
			<Share />
		</div>
	);
};

export default ServicesPage;
