import styled from "styled-components";
import { SwiperSlide } from "swiper/react";

export const Section = styled.section`
	width: 100%;
	position: relative;
	left: 0;
	right: 0;
	margin: 140px auto 0 auto;
`;

export const SectionHeading = styled.h2`
	text-align: center;
`;

export const TextRow = styled.span`
	font-family: Inter, sans-serif;
	font-weight: 500;
	font-size: calc(24px + 1.5vw);
	display: block;
	text-transform: uppercase;

	@media (min-width: 1600px) {
		font-size: 48px;
		line-height: 58.09px;
	}
`;

export const ReputationRow = styled.div`
	width: calc(100% + 100px);
	margin-top: calc(30px + 3.125vw);
	display: flex;
`;

export const ReputationImage = styled.img`
	display: block;
	width: 100%;
	height: auto;

	@media (min-width: 1600px) {
		width: 452px;
	}
`;

export const CustomSwiperSlide = styled(SwiperSlide)`
	max-width: 452px;
`;
