import {
	Section,
	SectionHeading,
	TextRow,
	SliderImage,
	CustomSwiperSlide,
} from "./styles";
import { Swiper } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";
import sliderImage1 from "../../../../Assets/Images/Culture/culture-slider1.webp";
import sliderImage2 from "../../../../Assets/Images/Culture/culture-slider2.webp";
import sliderImage3 from "../../../../Assets/Images/Culture/culture-slider3.webp";
import sliderImage4 from "../../../../Assets/Images/Culture/culture-slider4.webp";

const HeroSection = () => {
	return (
		<Section>
			<SectionHeading>
				<TextRow>of talented</TextRow>
				<TextRow>thinkers and builders</TextRow>
			</SectionHeading>
			<Swiper
				loop={true}
				autoplay={{
					delay: 5000,
					disableOnInteraction: false,
					pauseOnMouseEnter: true,
				}}
				modules={[FreeMode, Autoplay]}
				spaceBetween={20}
				slidesPerView="auto"
				breakpoints={{
					1392: {
						slidesPerView: 3.265,
					},
					1600: {
						slidesPerView: 3.265,
					},
				}}
			>
				<CustomSwiperSlide type={"big"}>
					<SliderImage src={sliderImage1} alt="slider" />
				</CustomSwiperSlide>
				<CustomSwiperSlide type={"small"}>
					<SliderImage src={sliderImage2} alt="slider" />
				</CustomSwiperSlide>
				<CustomSwiperSlide type={"big"}>
					<SliderImage src={sliderImage3} alt="slider" />
				</CustomSwiperSlide>
				<CustomSwiperSlide type={"small"}>
					<SliderImage src={sliderImage4} alt="slider" />
				</CustomSwiperSlide>
			</Swiper>
		</Section>
	);
};

export default HeroSection;
