import styled from "styled-components";

import iphoneFrame1 from "../../../../Assets/Images/Home/iphoneFrame1.webp";
import iphoneFrame2 from "../../../../Assets/Images/Home/iphoneFrame2.webp";
import iphoneFrame3 from "../../../../Assets/Images/Home/iphoneFrame3.webp";
import iphoneFrame4 from "../../../../Assets/Images/Home/iphoneFrame4.webp";


const iphoneFrames = [iphoneFrame1, iphoneFrame2, iphoneFrame3, iphoneFrame4,];

export const Section = styled.section`
	min-height: 100vh;
`

export const SectionContainer = styled.div`
	display: none;
	width: 100%;
	justify-content: center;
	align-items: center;
	height: 100vh;
	gap: calc(10px + 2.5vw);
	max-width: 992px;
	margin: 0 auto;

    @media (max-width: 768px) {
        flex-direction: column;
    }
	
	@media (min-width: 1600px) {
		gap: 50px;
	}
`

export const IphoneContainer = styled.div`
	position: relative;
	width: 457px;
	height: 656px;
	background-image: url(${(props) => iphoneFrames[Number(props.sectionIndex)]});
	background-size: 100% 100%;
	background-position: 0 0;
	overflow: hidden;

	@media (min-width: 1000px) and (max-width: 1200px) {
		width: 411.3px;
		height: 590.4px;
	}

	@media (min-width: 900px) and (max-width: 1000px) {
		width: 365.6px;
		height: 524.8px;
	}

	@media (min-width: 800px) and (max-width: 900px) {
		width: 319.9px;
		height: 459.2px;
	}

	@media (min-width: 700px) and (max-width: 800px) {
		width: 274.2px;
		height: 393.6px;
	}

	@media (min-width: 600px) and (max-width: 700px) {
		width: 228.5px;
		height: 328px;
	}

	@media (max-width: 600px) {
		width: 182.8px;
		height: 262.4px;
	}

	@media (min-width: 1600px) {
		width: 457px;
		height: 656px;
	}
`
export const ContextContainer = styled.div`
	width: 50%;
	@media screen and (max-width: 768px) {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
`

export const SectionHeading = styled.h3`
	font-size: calc(12px + 1.25vw);
	font-weight: 500;
	line-height: calc(12px + 1.671vw);
	text-align: left;
	margin: 0;

	@media screen and (max-width: 768px) {
		font-size: 20px;
		line-height: 24px;
	}
	@media (min-width: 1600px) {
		font-size: 32px;
		line-height: 38.73px;
	}
`

export const PhoneContent = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	box-sizing: border-box;

	& > div > img {
		width: 172px;
		height: 100%;
		transform: translateX(3px);
	}

    @media (min-width: 1000px) and (max-width: 1200px) {
        & > div > img {
            width: 100px;
            height: 154px;
        }
    }

    @media (min-width: 900px) and (max-width: 1000px) {
        & > div > img {
            width: 83px;
            height: 137px;
        }
    }

    @media (min-width: 800px) and (max-width: 900px) {
        & > div > img {
            width: 66px;
            height: 120px;
        }
    }

    @media (min-width: 700px) and (max-width: 800px) {
        & > div > img {
            width: 49px;
            height: 103px;
        }
    }

    @media (max-width: 700px) {
        & > div > img {
            width: 90px;
            height: 144px;
        }
    }
`

export const SubHeading = styled.h6`
	font-size: calc(10px + 0.625vw);
	font-weight: 500;
	line-height: calc(10px + 0.762vw);
	letter-spacing: 0.02em;
	text-align: left;
	margin: 20px 0;
	@media screen and (max-width: 768px) {
		font-size: 12px;
		line-height: 14px;
		margin: 10px 0;
	}
	@media (min-width: 1600px) {
		font-size: 20px;
		line-height: 24.2px;
	}
`

export const GrowthList = styled.p`
	margin-top: 12px;
	font-size: calc(10px + 0.625vw);
	font-weight: 400;
	line-height: calc(10px + 0.762vw);
	letter-spacing: 0.02em;
	text-align: left;
	@media screen and (max-width: 768px) {
		font-size: 12px;
		line-height: 14px;
	}
	@media (min-width: 1600px) {
		font-size: 20px;
		line-height: 24.2px;
	}
`

export const Button = styled.button`
	cursor: pointer;
	margin-top: 12px;
	padding: 10px 28px;
	border-radius: 90px;
	background: #000000;

	font-size: calc(10px + 0.625vw);
	font-weight: 500;
	line-height: calc(10px + 0.762vw);
	letter-spacing: 0.02em;
	color: #FFFFFF;
	@media screen and (max-width: 768px) {
		margin-top: 4px;
		font-size: 16px;
		line-height: 19px;
	}
	@media (min-width: 1600px) {
		font-size: 20px;
		line-height: 24.2px;
	}
`