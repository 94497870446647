import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	left: 0;
	right: 0;
	max-width: 1400px;
	margin: 140px auto 0 auto;
`;

export const SectionHeading = styled.h1`
	display: flex;
	flex-direction: column;
`;

export const TextRow = styled.span`
	font-size: calc(24px + 4vw);
	font-weight: 500;
	line-height: calc(24px + 4vw);
	text-align: left;
	text-transform: uppercase;

	&:first-child {
		margin-left: calc(30px + 3.688vw);
	}

	@media (min-width: 992px) and (max-width: 1200px) {
		font-size: calc(20px + 3vw);
		line-height: calc(20px + 3vw);
	}

	@media (min-width: 1200px) and (max-width: 1400px) {
		font-size: calc(24px + 3.5vw);
		line-height: calc(24px + 3.5vw);
	}
	
	@media (max-width: 768px) {
        &:first-child {
            margin-left: 0;
        }
        &:nth-child(2) {
            margin-left: 52px;
        }
	}
	
	@media (max-width: 480px) {
        font-size: 32px;
        line-height: 38.73px;
    }
	
	@media (min-width: 1600px) {
		font-size: 96px;
		line-height: 96px;

		&:first-child {
			margin-left: 89px;
		}
	}
`;

export const StepWrapper = styled.div`
	margin-top: calc(30px + 10.188vw);
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(20px + 3.75vw);
	align-items: center;
	
	@media (max-width: 480px) {
		margin-top: 30px;
	}

	@media (min-width: 1600px) {
		margin-top: 193px;
		gap: 80px;
	}
`;

export const StepContainer = styled.div`
	width: 100%;
	display: flex;
	gap: calc(15px + 2.813vw);
	max-width: 1080px;

	@media (max-width: 1200px) {
		img {
			width: 400px;
			height: 400px;
		}
	}

	@media (max-width: 996px) {
		img {
			width: 300px;
			height: 300px;
		}
	}

	@media (max-width: 768px) {
		img {
			width: 200px;
			height: 200px;
		}
	}

    @media (max-width: 480px) {
		&:nth-child(odd) {
			flex-direction: column;
		}
		&:nth-child(even) {
			flex-direction: column-reverse;
		}
		
        img {
            width: 100%;
            height: 100%;
        }
    }

	@media (min-width: 1600px) {
		gap: 60px;
	}
`;
export const StepInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	span {
		font-family: Inter, sans-serif;
		font-size: calc(12px + 1.25vw);
		font-weight: 500;
		line-height: calc(16px + 1.421vw);
		text-align: left;
	}

	p {
		font-family: Inter, sans-serif;
		font-size: calc(10px + 0.625vw);
		font-weight: 400;
		line-height: calc(12px + 0.762vw);
		letter-spacing: 0.02em;
		text-align: left;
	}

    @media (min-width: 480px) {
        span {
            font-size: 20px;
            line-height: 24.2px;
        }

        p {
            font-size: 12px;
            line-height: 14.52px;
        }
    }

	@media (min-width: 1600px) {
		span {
			font-size: 32px;
			line-height: 38.73px;
		}

		p {
			font-size: 20px;
			line-height: 24.2px;
		}
	}
`;

export const StepButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: calc(30px + 5.625vw);
`;
