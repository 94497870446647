import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	left: 0;
	right: 0;
	max-width: 1400px;
	margin: 140px auto 0 auto;
`;

export const SectionHeading = styled.h1`
	display: flex;
	flex-direction: column;
`;

export const TextRow = styled.span`
    font-family: Inter, sans-serif;
    font-size: calc(5px + 5vw);
    font-weight: 500;
    line-height: calc(15px + 5.56vw);
    text-align: left;
    text-transform: uppercase;

    &:first-child {
        margin-left: 192px;
    }

    &:nth-child(2) {
        margin-top: -23px;
    }

    &:nth-child(3) {
        margin-top: -23px;
        margin-left: 93px;
    }

    @media (max-width: 1200px) {
        font-size: calc(5px + 4.7vw);

        &:nth-child(2) {
            margin-top: 0;
            margin-left: 9px;
        }

        &:nth-child(3) {
            margin-top: 0;
            margin-left: 60px;
        }
    }

    @media (max-width: 768px) {
        &:first-child {
            margin-left: 30px;
        }

        &:nth-child(2) {
            margin-top: 0;
            margin-left: 9px;
        }

        &:nth-child(3) {
            margin-top: 0;
            margin-left: 60px;
        }

        &:nth-child(4) {
            margin-top: 0;
            margin-left: 30px;
        }

        &:nth-child(5) {
            margin-top: 0;
            margin-left: 0;
        }
    }

    @media (min-width: 320px) and (max-width: 480px) {
        font-size: calc(12px + 5.417vw);
        line-height: calc(16px + 5.985vw);
    }

    @media (max-width: 320px) {
        font-size: 32px;
        line-height: 38.73px;
    }

    @media (min-width: 1600px) {
        font-size: 96px;
        line-height: 116px;
    }
`;

export const ShareWrapper = styled.div`
	width: 100%;
	margin-top: calc(20px + 6.25vw);
	display: flex;
	justify-content: flex-end;

	form {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 58.3125%;
		gap: calc(5px + 0.688vw);
	}

	form input {
		width: 100%;
		border-radius: 30px;
		font-family: Inter, sans-serif;
		font-size: calc(12px + 0.75vw);
		font-weight: 400;
		line-height: calc(16px + 0.816vw);
		letter-spacing: 0.02em;
		text-align: left;
		background-color: #fafafa;
		padding: calc(15px + 0.938vw) calc(14px + 0.875vw);
		border: none;
		box-sizing: border-box;
	}

	form input::placeholder {
		color: #000000;
	}

	form button {
		width: 100%;
		padding: calc(5px + 0.313vw) calc(14px + 0.875vw);
		border-radius: 90px;
		background-color: #000000;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: calc(12px + 1vw);

		font-family: Inter, sans-serif;
		font-size: calc(12px + 1.25vw);
		font-weight: 500;
		line-height: calc(16px + 1.421vw);
		letter-spacing: 0.02em;
		text-align: left;
		color: #ffffff;
	}

	@media (max-width: 1200px) {
		form {
			width: 100%;
		}
	}

	@media (min-width: 1600px) {
        margin-top: 120px;
		
		form {
			width: 58.3125%;
			gap: 16px;
		}

		form input {
			font-size: 24px;
			line-height: 29.05px;
			padding: 30px 28px;
		}

		form button {
			padding: 10px 28px 10px 28px;
			margin-top: 28px;

			font-size: 32px;
			line-height: 38.73px;
		}
	}
`;
