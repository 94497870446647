import {
	Section,
	TextRow,
	SectionHeading,
	FormContainer,
	CollaborateContainer,
	ExpectationList,
	ContactContainer,
	Form,
	Input,
	TextArea,
	FormButton,
} from "./styles";

const HeroSection = () => {
	return (
		<Section>
			<SectionHeading>
				<TextRow>Hey! Tell us all</TextRow>
				<TextRow>the things</TextRow>
			</SectionHeading>
			<FormContainer>
				<CollaborateContainer>
					<h2>Let’s Collaborate.</h2>
					<p>
						At Spesna Solutions, we’re excited to hear about your
						project. Share your vision with us, and together we’ll
						plan the next steps for a successful collaboration.
					</p>
					<h3>Here’s what you can expect:</h3>
					<ExpectationList>
						<span>→ We'll get back to you within 24 hours</span>
						<span>→ If needed, feel free to request an NDA</span>
						<span>
							→ Your will be talking with our tech project
							managers
						</span>
					</ExpectationList>
					<h4>Or</h4>
					<hr />
					<ContactContainer>
						<span>Call us +374 77 73 33 73</span>
						<span>Say hi contact@spesna.com</span>
					</ContactContainer>
				</CollaborateContainer>
				<Form>
					<Input type={"text"} placeholder={"Name*"} />
					<Input type={"text"} placeholder={"Email*"} />
					<Input type={"text"} placeholder={"Phone Number"} />
					<Input type={"text"} placeholder={"Company"} />
					<Input type={"text"} placeholder={"Website"} />
					<TextArea placeholder={"Message*"} />
					<FormButton>Send</FormButton>
				</Form>
			</FormContainer>
		</Section>
	);
};

export default HeroSection;
