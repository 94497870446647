import {
	Section,
	SectionHeading,
	TextRow,
	ReputationRow,
	ReputationImage,
	CustomSwiperSlide,
} from "./styles";

import reputation1 from "../../../../Assets/Images/Home/reputation1.webp";
import reputation2 from "../../../../Assets/Images/Home/reputation2.webp";
import reputation3 from "../../../../Assets/Images/Home/reputation3.webp";

import { FreeMode, Autoplay } from "swiper/modules";
import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";

const Reputation = () => {
	return (
		<Section>
			<SectionHeading>
				<TextRow>Reputation is everything</TextRow>
				<TextRow>so ours is flawless</TextRow>
			</SectionHeading>

			<ReputationRow>
				<Swiper
					loop={true}
					autoplay={{
						delay: 5000,
						disableOnInteraction: false,
						pauseOnMouseEnter: true,
					}}
					modules={[FreeMode, Autoplay]}
					spaceBetween={20}
					slidesPerView="auto"
					breakpoints={{
						0: {
							slidesPerView: 1.265,
						},
						992: {
							slidesPerView: 2.265,
						},
						1392: {
							slidesPerView: 3.265,
						},
						1600: {
							slidesPerView: "auto",
						},
					}}
				>
					<CustomSwiperSlide>
						<ReputationImage src={reputation1} alt="" />
					</CustomSwiperSlide>
					<CustomSwiperSlide>
						<ReputationImage src={reputation2} alt="" />
					</CustomSwiperSlide>
					<CustomSwiperSlide>
						<ReputationImage src={reputation3} alt="" />
					</CustomSwiperSlide>
					<CustomSwiperSlide>
						<ReputationImage src={reputation1} alt="" />
					</CustomSwiperSlide>
					<CustomSwiperSlide>
						<ReputationImage src={reputation1} alt="" />
					</CustomSwiperSlide>
				</Swiper>
			</ReputationRow>
		</Section>
	);
};

export default Reputation;
