import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	position: relative;
	left: 0;
	right: 0;
	margin: 140px auto 0 auto;
	max-width: 1245px;
	display: flex;
	flex-direction: column;
	gap: calc(5px + 4.688vw);

	@media (min-width: 1600px) {
		gap: 80px;
	}
`;

export const ServiceRow = styled.div`
	display: flex;
	width: 100%;
	gap: calc(5px + 2.5vw);

    @media (max-width: 1300px) {
        gap: 2.5vw;
    }

	@media (min-width: 1600px) {
		gap: 45px;
	}
`;

export const ServiceContainer = styled.div`
	width: 50%;
	max-width: 600px;
	display: flex;
	gap: calc(6px + 0.625vw);
	box-sizing: border-box;

	div:first-child {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fafafa;
		width: 240px;
		height: 240px;
		border-radius: 30px;
	}

	div:first-child > img {
		width: 80px;
		height: 80px;
	}

	@media (min-width: 1300px) and (max-width: 1400px) {
		div:first-child {
			height: auto;
		}

		div:first-child > img {
			width: 68px;
			height: 68px;
		}
	}

	@media (min-width: 1100px) and (max-width: 1300px) {
		div:first-child {
            border-radius: 10px;
            width: 168px;
            height: 100%;
		}

		div:first-child > img {
			width: 44px;
			height: 44px;
		}
	}

    @media (max-width: 1100px) {
		flex-direction: column;
		width: 100%;
        gap: calc(3px + 0.625vw);
        div:first-child {
            border-radius: 10px;
            width: 128px;
            height: 128px;
        }

        div:first-child > img {
            width: 32px;
            height: 32px;
        }
    }

	@media (min-width: 480px) and (max-width: 996px) {
		div:first-child {
            border-radius: 10px;
            width: 100px;
            height: 100px;
		}
	}

	@media (min-width: 320px) and (max-width: 480px) {
		div:first-child {
			width: 60px;
			height: 60px;
		}

		div:first-child > img {
			width: 32px;
			height: 32px;
		}
	}

    @media (max-width: 320px) {
        div:first-child {
            width: 42px;
            height: 42px;
        }

        div:first-child > img {
            width: 24px;
            height: 24px;
        }
    }

	@media (min-width: 1600px) {
		gap: 16px;
	}
`;

export const ServiceInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: calc(5px + 0.313vw);
	box-sizing: border-box;
	max-width: 328px;

	span {
		font-family: Inter, sans-serif;
		font-size: calc(12px + 0.75vw);
		font-weight: 500;
		line-height: calc(15px + 0.878vw);
		text-align: left;
	}

	p {
		font-family: Inter, sans-serif;
		font-size: calc(8px + 0.5vw);
		font-weight: 400;
		line-height: calc(10px + 0.585vw);
		letter-spacing: 0.02em;
		text-align: left;
		margin: 0;
	}
	
    @media (min-width: 320px) and (max-width: 480px) {
        span {
            font-size: 18px;
            line-height: 24px;
        }

        p {
            font-size: 12px;
            line-height: 18px;
        }
    }
	
    @media (max-width: 320px) {
        span {
            font-size: 14px;
            line-height: 16.94px;
        }

        p {
            font-size: 10px;
            line-height: 12.1px;
        }
	}
		
	@media (min-width: 1600px) {
		gap: 10px;

		span {
			font-size: 24px;
			line-height: 29.05px;
		}

		p {
			font-size: 16px;
			line-height: 19.36px;
		}
	}
`;
