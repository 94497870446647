import {
	FonderAvatarContainer,
	FonderContainer,
	FonderReviewBox,
	Section,
	FounderAvatar,
	TextRow,
	SectionHeading,
	FonderNameContainer,
} from "./styles";

import fonderAvatar from "../../../../Assets/Images/Culture/fonderAvatar.webp";
import { useMobileDetector } from "../../../../utils/customHooks";

const OurDigitalHeaven = () => {
	const isMobile = useMobileDetector();

	return (
		<Section>
			<SectionHeading>
				{isMobile ? (
					<>
						<TextRow>Welcome to</TextRow>
						<TextRow>our Digital</TextRow>
						<TextRow>heaven</TextRow>
					</>
				) : (
					<>
						<TextRow>Welcome to our</TextRow>
						<TextRow>Digital heaven</TextRow>
					</>
				)}
			</SectionHeading>
			<FonderContainer>
				<FonderReviewBox>
					<p>
						In January 2020, three visionaries came together with
						one shared goal: to build a digital IT company that
						would shape the future of technology. Each of the
						founding leaders brought a unique skill set and a
						relentless passion for innovation, and from their
						collaboration, Spesna was born.
						<br />
						<br />
						The journey began with a simple idea: to help businesses
						thrive in the digital world by providing high-quality,
						custom digital solutions. With a firm belief in the
						power of technology and design, the founders spent
						countless days and nights refining their vision,
						perfecting their processes, and ensuring every detail of
						their services was crafted with precision.
						<br />
						<br />
						Together, these three leaders combined their expertise
						in design, development, and marketing, turning Spesna
						into a dynamic digital powerhouse. Despite launching in
						early 2020 amidst the challenges of a global pandemic,
						their unwavering dedication and hard work allowed the
						company to rise and thrive. From a small, ambitious
						team, Spesna grew into a thriving IT firm that pushed
						the boundaries of what was possible in the digital
						world.
					</p>
					<FonderAvatarContainer>
						<FounderAvatar
							src={fonderAvatar}
							alt={"fonderAvatar"}
						/>
						<FonderNameContainer>
							<h5>Fonder & CEO</h5>
							<h6>Of Spesna</h6>
						</FonderNameContainer>
					</FonderAvatarContainer>
				</FonderReviewBox>
			</FonderContainer>
		</Section>
	);
};

export default OurDigitalHeaven;
