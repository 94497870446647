import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	position: relative;
	left: 0;
	right: 0;
	margin: 173px auto 0 auto;
	max-width: 1400px;
	display: flex;
	flex-direction: row;
	gap: calc(20px + 1.25vw);
	flex-flow: row wrap; /* Allow items to wrap into multiple lines */
	box-sizing: border-box;

	@media (min-width: 1600px) {
		gap: 40px;
	}
`;

export const WorkContainer = styled.div`
    cursor: pointer;
    width: ${(props) => (props.type === "small" ? "calc(50% - 20px)" : "100%")};
    display: flex;
    flex-direction: column;
    gap: calc(10px + 0.625vw);
    flex-flow: column;

    img {
        width: 100%;
    }

    span {
        font-size: calc(12px + 0.75vw);
        font-weight: 500;
        line-height: calc(16px + 0.816vw);
        text-align: left;
    }

    p {
        font-size: calc(8px + 0.5vw);
        font-weight: 400;
        line-height: calc(8px + 0.71vw);
        letter-spacing: 0.02em;
        text-align: left;
    }

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        span {
            font-size: calc(14px + 0.75vw);
            line-height: calc(18px + 0.816vw);
        }

        p {
            font-size: calc(10px + 0.5vw);
            line-height: calc(10px + 0.71vw);
        }
    }

    @media (max-width: 320px) {
        span {
            font-size: 20px;
            line-height: 24.2px;
        }

        p {
            font-size: 12px;
            line-height: 14.52px;
        }
    }

    @media (min-width: 1600px) {
        gap: 20px;

        span {
            font-size: 24px;
            line-height: 29.05px;
        }

        p {
            font-size: 16px;
            line-height: 19.36px;
        }
    }
`;
