import HeroSection from "../../Components/Organisms/ContactUs/HeroSection";
import ContactsSection from "../../Components/Organisms/ContactUs/ContactsSection";

const ContactUsPage = () => {
	return (
		<div>
			<HeroSection />
			<ContactsSection />
		</div>
	);
};

export default ContactUsPage;
