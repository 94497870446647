import styled from "styled-components";

export const Section = styled.section`
	flex: 1;
	min-height: 600px;
	max-height: 1100px;
	width: 100%;
	height: calc(100vh - 127px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;
	max-width: 1400px;
	margin: 0 auto;

	@media (min-width: 1200px) {
		padding-top: 60px;
    }
`;

export const SectionHeading = styled.h1`
	font-size: calc(24px + 4.5vw);
	font-weight: 500;
	line-height: calc(32px + 5.25vw);
	text-align: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	text-transform: uppercase;
	white-space: nowrap;
	margin: 0 auto;
	width: min-content;

	@media (min-width: 768px) and (max-width: 1200px) {
		white-space: wrap;
	}

	@media (min-width: 320px) and (max-width: 768px) {
		font-size: calc(12px + 6.25vw);
		line-height: calc(16px + 7.031vw);
	}

	@media (max-width: 320px) {
		font-size: calc(8px + 7.5vw);
		line-height: calc(12px + 8.353vw);
		text-align: left;
		white-space: nowrap;
	}

	@media (min-width: 1600px) {
		font-size: 96px;
		line-height: 116.18px;
	}
`;

export const TextRow = styled.span`
	width: min-content;

	&:nth-child(2) {
		width: 100%;
		margin: -20px 20px 0 -230px;
		display: flex;
		flex-direction: row;
		justify-content: end;
		z-index: 2;
	}

	&:nth-child(3) {
		margin: -20px 0 0 38px;
	}

	@media (min-width: 768px) and (max-width: 996px) {
		&:nth-child(2) {
			margin-left: 0;
		}

		&:nth-child(3) {
			width: 100%;
			margin: -8px 0 0 0;
			display: flex;
			justify-content: flex-start;
		}
	}

	@media (min-width: 320px) and (max-width: 768px) {
		&:nth-child(2) {
			width: 100%;
			display: flex;
			justify-content: center;
			margin: calc(-4px - 0.25vw) 0 0 calc(-20px - 1.25vw);
		}

		&:nth-child(3),
		&:nth-child(5) {
			width: 100%;
			display: flex;
			justify-content: flex-end;
            margin: calc(-4px - 0.25vw) 0 0 0;
		}

		&:nth-child(4) {
			width: 100%;
			display: flex;
			justify-content: flex-start;
            margin: calc(-4px - 0.25vw) 0 0 0;
		}
	}

	@media (max-width: 320px) {
		&:nth-child(2) {
            width: 100%;
            display: flex;
            justify-content: center;
			margin: calc(-3px - 0.188vw) 0 0 0;
		}

		&:nth-child(3), &:nth-child(5) {
			width: 100%;
            display: flex;
            justify-content: flex-end;
            margin: calc(-3px - 0.188vw) 0 0 0;
        }

		&:nth-child(4) {
            width: 100%;
            display: flex;
            justify-content: flex-start;
			margin: calc(-3px - 0.188vw) 0 0 0;
		}
	}
`;

export const RoundedShape = styled.img`
	position: absolute;
	width: calc(113px + 50vw);
	height: calc(113px + 50vw);
	margin: calc(-35px - 14.375vw) calc(-32px - 14.875vw) 0 0;
	z-index: -1;

	@media (min-width: 768px) and (max-width: 996px) {
		margin: calc(-15px - 21.875vw) calc(-20px - 11.089vw) 0 0;
	}

	@media (min-width: 480px) and (max-width: 768px) {
		margin: calc(-75px - 14.375vw) calc(-10px - 10.875vw) 0 0;
		z-index: 1;
	}

    @media (min-width: 320px) and (max-width: 480px) {
        width: calc(180px + 37.5vw);
        height: calc(180px + 37.5vw);
        margin: calc(-68px - 14.167vw) calc(-35.5px - 7.396vw) 0 0;
        z-index: 1;
    }

	@media (max-width: 320px) {
		width: calc(125px + 39.063vw);
		height: calc(125px + 39.063vw);
		margin: calc(-52px - 16.25vw) calc(-19px - 5.938vw) 0 0;
        z-index: 1;
		display: block;
	}

	@media (min-width: 1600px) {
		width: 913.88px;
		height: 913.88px;
		margin: -265px -270px 0 0;
	}
`;

export const ShippedContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	& > img {
		width: 100%;
	}

	span {
		font-family: Inter, sans-serif;
		font-size: calc(10px + 0.625vw);
		font-weight: 500;
		line-height: calc(12px + 0.762vw);
		letter-spacing: 0.02em;
		text-align: center;
		color: #000;
		display: flex;
		gap: 12px;
		margin-bottom: calc(-10px - 2.5vw);
	}

	span > img {
		width: 24px;
		height: 24px;
		filter: brightness(0) saturate(100%);
		transform: rotateX(180deg);
	}

	@media (min-width: 1600px) {
		span {
			font-size: 20px;
			line-height: 24.2px;
			gap: 12px;
			margin-bottom: -50px;
		}

		span > img {
			width: 24px;
			height: 24px;
		}
	}
`;

// export const TrustedParagraph = styled.p`
// 	font-size: 20px;
// 	font-weight: 400;
// 	line-height: 24.2px;
// 	letter-spacing: 0.02em;
// 	text-align: left;
// `

export const HeroButton = styled("button")`
	cursor: pointer;
	margin-top: 3.75vw;
	padding: 16px 28px;
	border-radius: 90px;
	background: #000000;
	font-size: calc(10px + 1.125vw);
	font-weight: 500;
	line-height: calc(10px + 1.493vw);
	letter-spacing: 0.02em;
	color: #ffffff;
	z-index: 3;
	border: none;

	@media (max-width: 320px) {
		width: 100%;
		font-size: 16px;
		line-height: 19.36px;
	}

	@media (min-width: 1600px) {
		font-size: 28px;
		line-height: 33.89px;
		margin-top: 80px;
	}
`;

export const TrustedIcons = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;

	span {
		font-size: calc(10px + 0.625vw);
		font-weight: 400;
		line-height: 24.2px;
		letter-spacing: 0.02em;
		text-align: left;
	}

	@media (min-width: 1600px) {
		gap: 5px;

		span {
			font-size: 20px;
			line-height: 24.2px;
		}
	}
`;
