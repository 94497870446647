import styled from "styled-components";

export const FooterSection = styled.footer`
    position: absolute;
    left: 0;
    right: 0;
    margin: 140px auto 0 auto;
    background: #101010;
    padding: 48px 142px 64px 100px;

    & > div {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 1400px;

        & > div {
            height: 100%;
            width: 50%;
        }
    }

    @media (min-width: 1300px) and (max-width: 1500px) {
        padding: 30px 98px 40px 78px;
    }

    @media (min-width: 1000px) and (max-width: 1300px) {
        padding: 24px 70px 20px 40px;
    }

    @media (min-width: 768px) and (max-width: 1000px) {
        padding: 12px 35px 12px 20px;
    }

    @media (max-width: 768px) {
        padding: 30px 16px;

        & > div {
            flex-wrap: wrap;
            flex-direction: column;

            & > div {
                width: 100%;
            }
        }
    }
`;

export const CompanyInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: calc(3px + 1.313vw);
	
	img {
		width: 151px;
	}

	& > div {
		display: flex;
		flex-direction: column;
		gap: calc(1.5px + 0.656vw);
    }

	& > div img {
		width: calc(3px + 1.313vw);
		height: calc(3px + 1.313vw);
		margin-left: 0.75vw;
		margin-bottom: -0.313vw;
	}
	
	@media (max-width: 480px) {
		& > div > span {
            font-size: 12px;
            line-height: 14.52px;
        }
        img {
            width: 121px;
        }
        & > div img {
			width: 15px;
			height: 15px;
            margin-bottom: -0.833vw;
        }
	}

	@media (min-width: 1600px) {
		gap: 24px;

		& > div {
			gap: 12px;
		}
		& > div img {
			width: 24px;
			height: 24px;
            margin-left: 12px;
            margin-bottom: -5px;
		}
	}
`;

export const TextRow = styled.span`
	font-size: calc(7px + 0.94vw);
	font-weight: 400;
	line-height: calc(3.025px + 1.323vw);
	letter-spacing: 0.02em;
	text-align: left;
	color: #fafafa;

	@media (min-width: 1600px) {
		font-size: 20px;
		line-height: 24.2px;
	}
`;

export const BusinessIdea = styled.div`
	& > div:first-child > span {
		font-size: calc(3.5px + 1.531vw);
		line-height: calc(4.23625px + 2.092vw);
	}

	& > div:nth-child(2) > span {
		width: 100%;
		font-size: calc(20px + 4.2vw);
		line-height: calc(14.5225px + 7.17vw);
		text-transform: uppercase;
		border-bottom: 6px #fafafa solid;
	}

	img {
		width: calc(13px + 6.016vw);
		height: calc(13px + 6.016vw);
		margin-bottom: -1.25vw;
	}
	
	@media (max-width: 996px) {
        & > div:nth-child(2) > span {
            font-size: calc(16px + 4.2vw);
        }
	}
	
	@media (max-width: 768px) {
        margin-top: 30px;
	}
	
	@media (max-width: 480px) {
		& > div:first-child > span {
            font-size: 16px;
            line-height: 19.36px;
        }
        & > div:nth-child(2) > span {
            font-size: 42px;
            line-height: 50.83px;
        }
        img {
            width: 51px;
			height: 51px;
            margin-bottom: -10px;
        }
	}

	@media (min-width: 1600px) {
		& > div:first-child > span {
			font-size: 28px;
			line-height: 33.89px;
		}
		& > div:nth-child(2) > span {
			font-size: 96px;
			line-height: 116.18px;
		}
		img {
			width: 110px;
			height: 110px;
            margin-bottom: -20px;
		}
	}
`;

export const PrivacyPolicy = styled.div`
	display: flex;
	flex-direction: row;
	gap: calc(5px + 2.188vw);
	margin-top: calc(20px + 3.75vw);

	@media (max-width: 768px) {
		margin-top: 24px;
		flex-direction: column;
		gap: 24px;
	}

    @media (max-width: 480px) {
        & > span {
            font-size: 12px;
            line-height: 14.52px;
        }
    }

	@media (min-width: 1600px) {
		gap: 40px;
        margin-top: 80px;
	}
`;

export const PageLinks = styled.div`
	display: flex;
    gap: calc(5px + 2.938vw);
	margin-top: 52px;
	
	@media (max-width: 768px) {
		margin-top: 30px;
		flex-direction: column;
	}

    @media (max-width: 480px) {
        & > span {
            font-size: 12px;
            line-height: 14.52px;
        }
    }

    @media (min-width: 1600px) {
        gap: 52px;
    }
`;

export const SocialLinks = styled.div`
	display: flex;
	gap: calc(5px + 2.938vw);
    margin-top: 52px;

	@media (max-width: 768px) {
		width: 100%;
		justify-content: space-evenly;
		margin-top: 40px;
	}

    @media (max-width: 480px) {
        justify-content: space-between;
		
        & > span {
            font-size: 12px;
            line-height: 14.52px;
        }
	}
	
    @media (min-width: 1600px) {
        gap: 52px;
    }
`;

export const Copyright = styled.div`
	margin-top: calc(20px + 3.75vw);

	@media (max-width: 768px) {
		width: 100%;
		display: flex;
		align-items: center;
		margin-top: 40px;
	}

    @media (max-width: 480px) {
		& > span {
            font-size: 12px;
            line-height: 14.52px;
		}
    }
	
	@media (min-width: 1600px) {
        margin-top: 80px;
	}
`;
