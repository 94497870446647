import { PopupResults } from "./styles";
import { TextRow } from "../styles";
import { useMobileDetector } from "../../../../../utils/customHooks";

const Results = ({ popupArrow }) => {
	const isMobile = useMobileDetector();

	return (
		<PopupResults>
			<div>
				<span>Results</span>
			</div>

			<div>
				{isMobile ? (
					<>
						<p>
							The newly developed platform made a big difference
							for Venhub, leading to increased sales and better
							customer engagement. Additionally, the platform
							supported Venhub’s growth and expansion, helping
							them strengthen their position as a leader in the
							vending machine tool industry.
						</p>
					</>
				) : (
					<>
						<p>The newly developed platform made a </p>
						<p>
							big difference for Venhub, leading to increased
							sales and better customer engagement. Additionally,
							the platform supported Venhub’s growth and
							expansion, helping them strengthen their position as
							a leader in the vending machine tool industry.
						</p>
					</>
				)}

				<button>
					Let’s talk and discuss your project{" "}
					<img src={popupArrow} alt="" />
				</button>
			</div>
		</PopupResults>
	);
};

export default Results;
