import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	left: 0;
	right: 0;
	display: flex;
	margin: 120px auto 0 auto;
	position: relative;
	max-width: 1400px;

	div {
		width: 100%;
		display: flex;
		max-width: 1400px;
		background-color: #000001;
		align-items: center;
		justify-content: center;
		border-radius: 60px;
	}

	img {
		width: 76.14%;
	}

	@media (max-width: 768px) {
		div {
			border-radius: 20px;
		}

		img {
			width: 100%;
			border-radius: 20px;
		}
	}
`;
