import styled from "styled-components";

export const Section = styled.section`
	flex: 1;
	min-height: 600px;
	width: 100%;
	box-sizing: border-box;
	padding-top: 60px;
	margin: 0 auto;
	max-width: 1400px;
`;

export const SectionHeading = styled.h1`
	font-size: 96px;
	font-weight: 500;
	line-height: 116px;
	text-align: left;
	display: flex;
	flex-direction: column;
	text-transform: uppercase;
	white-space: nowrap;
	margin: 0 auto 120px;
	width: min-content;
	@media (min-width: 1200px) and (max-width: 1400px) {
		font-size: 83px;
		line-height: 106px;
		margin: 0 auto 100px;
	}
	@media (min-width: 1100px) and (max-width: 1200px) {
		font-size: 74px;
		line-height: 96px;
		margin: 0 auto 80px;
	}
	@media (min-width: 992px) and (max-width: 1100px) {
		font-size: 63px;
		line-height: 96px;
		margin: 0 auto 60px;
	}
	@media screen and (max-width: 992px) {
		font-size: 10vw;
		line-height: 12.1vw;
		margin: 0 auto 40px;
	}
`;

export const TextRow = styled.span`
	width: min-content;
	display: block;
	&:nth-child(1) {
		margin-left: 140px;
	}
	&:nth-child(2) {
		margin-top: -30px;
	}
	@media screen and (max-width: 360px) {
		&:nth-child(1) {
			margin-left: 1.5vw !important;
		}
	}
	@media screen and (max-width: 992px) {
		&:nth-child(1) {
			margin-left: 3vw;
		}
		&:nth-child(2) {
			margin-top: -2vw;
		}
	}
`;

export const FormContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 30px;
	@media (max-width: 992px) {
		flex-direction: column;
	}
`;
export const CollaborateContainer = styled.div`
	width: 40%;
	@media (max-width: 992px) {
		width: 100%;
	}
	h2 {
		margin: 0;
		font-size: calc(8px + 2.5vw);
		font-weight: 500;
		line-height: calc(8px + 3.125vw);
		text-align: left;
	}

	p {
		margin: 40px 0 0 0;
		max-width: 524px;
		font-size: calc(8px + 1vw);
		font-weight: 400;
		line-height: calc(8px + 1.3vw);
		letter-spacing: 0.02em;
		text-align: left;
	}

	h3 {
		margin: 50px 0 0 0;
		font-size: calc(8px + 1vw);
		font-weight: 500;
		line-height: calc(8px + 1.3vw);
		letter-spacing: 0.02em;
		text-align: left;
	}

	h4 {
		font-size: calc(8px + 1vw);
		font-weight: 500;
		line-height: calc(8px + 1.3vw);
		letter-spacing: 0.02em;
		text-align: left;
		margin: 60px 0 0 0;
	}

	hr {
		margin: 10px 0 0 0;
		border: none;
		height: 1px;
		background-color: #d9d9d9;
	}
	@media screen and (max-width: 992px) {
		h2 {
			font-size: 20px;
			line-height: 24px;
		}

		p {
			margin: 12px 0 0 0;
			font-size: 12px;
			line-height: 14px;
		}

		h3 {
			margin: 16px 0 0 0;
			font-size: 16px;
			line-height: 19px;
		}
		h4 {
			margin: 16px 0 0 0;
			font-size: 16px;
			line-height: 19px;
		}
	}
	@media screen and (min-width: 1600px) {
		h2 {
			font-size: 48px;
			line-height: 58.09px;
		}

		p {
			margin: 40px 0 0 0;
			font-size: 24px;
			line-height: 29.05px;
		}

		h3 {
			margin: 50px 0 0 0;
			font-size: 24px;
			line-height: 29.05px;
		}
		h4 {
			font-size: 24px;
			line-height: 29.05px;
		}
	}
`;

export const ExpectationList = styled.div`
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	gap: 12px;

	span {
		font-size: calc(8px + 0.75vw);
		font-weight: 400;
		line-height: calc(8px + 1vw);
		letter-spacing: 0.02em;
		text-align: left;
	}
	
	@media screen and (max-width: 992px) {
		margin-top: 16px;
		span {
			font-size: 12px;
			line-height: 14px;
		}
	}
	@media screen and (min-width: 1600px) {
		span {
			font-size: 20px;
			line-height: 24.2px;
		}
	}
`;
export const ContactContainer = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 12px;

	span {
		font-size: calc(8px + 0.75vw);
		font-weight: 400;
		line-height: calc(8px + 1vw);
		letter-spacing: 0.02em;
		text-align: left;
	}
	
	@media screen and (max-width: 992px) {
		margin-top: 10px;
		gap: 6px;
		span {
			font-size: 12px;
			line-height: 14px;
		}
	}

	@media screen and (min-width: 1600px) {
		span {
			font-size: 20px;
			line-height: 24.2px;
		}
	}
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 50%;
	@media (max-width: 992px) {
		width: 100%;
	}
`;

export const Input = styled.input`
	width: 100%;
	padding: 28px;
	box-sizing: border-box;

	font-size: calc(8px + 1vw);
	font-weight: 400;
	letter-spacing: 0.02em;
	text-align: left;

	outline: none;
	border-radius: 30px;
	background-color: #fafafa;
	border: none;

	@media screen and (max-width: 992px) {
		font-size: 12px;
		line-height: 14px;
		border-radius: 16px;
		padding: 16px;
	}

	@media screen and (min-width: 992px) and (max-width: 1200px) {
		padding: 20px;
	}

	@media screen and (min-width: 1600px) {
		font-size: 24px;
	}
`;

export const TextArea = styled.textarea`
	box-sizing: border-box;
	resize: none;

	width: 100%;
	padding: 28px;
	height: 268px;

	outline: none;
	border-radius: 30px;
	background-color: #fafafa;
	border: none;

	font-size: calc(8px + 1vw);
	font-weight: 400;
	line-height: calc(8px + 1.3vw);
	letter-spacing: 0.02em;
	text-align: left;
	
	@media screen and (max-width: 992px) {
		font-size: 12px;
		line-height: 14px;
		height: 185px;
		border-radius: 16px;
		padding: 16px;
	}
	@media screen and (min-width: 992px) and (max-width: 1200px) {
		padding: 20px;
		height: 200px;
	}
	@media screen and (min-width: 1600px) {
		font-size: 24px;
		line-height: 29.05px;
	}
`;

export const FormButton = styled.button`
	cursor: pointer;
	margin-top: 28px;
	padding: 24.5px;
	box-sizing: border-box;
	width: 100%;
	background: #000000;
	border-radius: 90px;

	font-size: calc(8px + 1.5vw);
	font-weight: 500;
	line-height: calc(8px + 1.8vw);
	letter-spacing: 0.02em;
	color: #ffffff;

	border: none;
	
	@media screen and (max-width: 992px) {
		margin-top: 12px;
		padding: 10px;
	}
	
	@media screen and (min-width: 1600px) {
		font-size: 32px;
	}
`;
