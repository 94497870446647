import styled from "styled-components";

export const Section = styled.section`
	flex: 1;
	min-height: 600px;
	width: 100%;
	height: calc(100vh - 127px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;
	padding-top: 60px;
	max-width: 1400px;
	margin: 0 auto;
`;

export const SectionHeading = styled.h1`
	font-size: 140px;
	font-weight: 500;
	line-height: 169.43px;
	text-align: left;
	display: flex;
	flex-direction: column;
	text-transform: uppercase;
	white-space: nowrap;
	margin: 0 auto;
	width: min-content;

	@media (min-width: 1200px) and (max-width: 1400px) {
		font-size: 120px;
		line-height: 149px;
	}
	@media (min-width: 996px) and (max-width: 1200px) {
		font-size: 100px;
		line-height: 129px;
	}
	@media screen and (max-width: 996px) {
		font-size: 10vw !important;
		line-height: 11.875vw !important;
	}
`;

export const PinkBall = styled.img`
	width: 168px;
	height: 168px;
	margin: -20px 30px 0 134px;

	@media (min-width: 1200px) and (max-width: 1400px) {
		width: 148px;
		height: 148px;
		margin: -25px 25px 0 114px;
	}
	@media (min-width: 996px) and (max-width: 1200px) {
		width: 128px;
		height: 128px;
		margin: -20px 20px 0 100px;
	}
	@media screen and (max-width: 996px) {
		width: 12.15vw;
		height: 12.15vw;
		margin: 0 6vw 0 6.5vw;
	}
`;

export const TextRow = styled.span`
	width: min-content;
	&:nth-child(2) {
		margin-top: -35px;
		display: flex;
		flex-direction: row;
		justify-content: end;
	}
	&:nth-child(3) {
		margin: -35px 0 0 38px;
	}

	@media (min-width: 1200px) and (max-width: 1400px) {
		&:nth-child(2) {
			margin-top: -35px;
		}
		&:nth-child(3) {
			margin: -35px 0 0 38px;
		}
	}
	@media (min-width: 996px) and (max-width: 1200px) {
		&:nth-child(2) {
			margin-top: -35px;
		}
		&:nth-child(3) {
			margin: -35px 0 0 38px;
		}
	}
	@media screen and (max-width: 996px) {
		&:nth-child(2) {
			margin-top: -1.3vw;
		}
		&:nth-child(3) {
			margin: -1.3vw 0 0 1.3vw;
		}
	}
`;

export const PinkCube = styled.img`
	position: absolute;
	width: 542px;
	height: 542px;
	margin: -180px -200px 0 0;

	@media (min-width: 1200px) and (max-width: 1400px) {
		width: 442px;
		height: 442px;
		margin: -145px -153px 0 0;
	}
	@media (min-width: 996px) and (max-width: 1200px) {
		width: 342px;
		height: 342px;
		margin: -105px -100px 0 0;
	}
	@media screen and (max-width: 996px) {
		width: 41.25vw;
		height: 41.25vw;
		margin: -18vw -11vw 0 0;
		
	}
`;

export const TrustedContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media screen and (max-width: 992px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const TrustedParagraph = styled.p`
	font-size: calc(4px + 1vw);
	line-height: calc(4px + 1.25vw);
	font-weight: 400;
	letter-spacing: 0.02em;
	text-align: left;
	
	@media screen and (min-width: 1600px){
		font-size: 20px;
		line-height: 24.2px;
	}
	@media screen and (max-width: 996px){
		font-size: calc(4px + 2.5vw);
		line-height: calc(4.2px + 3.125vw);
	}
`;

export const TrustedIcons = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	span {
		font-size: calc(4px + 1vw);
		line-height: calc(4px + 1.25vw);
		font-weight: 400;
		letter-spacing: 0.02em;
		text-align: left;
	}
	img{
		width: 41.88vw;
	}
	@media screen and (min-width: 1600px){
		span{
			font-size: 20px;
			line-height: 24.2px;
		}
		img{
			width: 659px;
		}
	}
	@media screen and (max-width: 996px){
		span{
			font-size: calc(4px + 2.5vw);
			line-height: calc(4.2px + 3.125vw);
		}
		img{
			width: calc(100% + 16px);
		}
	}
`;
