import {
	Section,
	SectionHeading,
	ProductTitle,
	ProductDescription,
	ProductImage,
	TextRow,
	ProductContainer,
	ProductButton,
	ProductInfo,
	ProductImages,
	ProductRow,
	RectangleBackground,
} from "./styles";
import selectedCases1 from "../../../../Assets/Images/Home/selectedCases1.webp";
import selectedCases2 from "../../../../Assets/Images/Home/selectedCases2.webp";
import selectedCases3 from "../../../../Assets/Images/Home/selectedCases3.webp";
import selectedCases4 from "../../../../Assets/Images/Home/selectedCases4.webp";
import selectedCases5 from "../../../../Assets/Images/Home/selectedCases5.webp";
import selectedCases6 from "../../../../Assets/Images/Home/selectedCases6.webp";

const SelectedCases = () => {
	return (
		<Section>
			<SectionHeading>
				<TextRow>Selected</TextRow>
				<TextRow>Cases</TextRow>
			</SectionHeading>

			<ProductRow>
				<ProductContainer size={"large"}>
					<ProductInfo size={"large"}>
						<div>
							<ProductTitle>Moducoin</ProductTitle>
							<ProductDescription>
								Crypto exchange wallet multi-currency wallet for
								buying, selling, and exchanging
								cryptocurrencies.
							</ProductDescription>
						</div>
						<ProductButton>Mobile App</ProductButton>
					</ProductInfo>
					<ProductImages>
						<div>
							<ProductImage
								size={"large"}
								src={selectedCases1}
							></ProductImage>
						</div>
						<div>
							<ProductImage
								size={"small"}
								src={selectedCases2}
							></ProductImage>
						</div>
					</ProductImages>
				</ProductContainer>
				<ProductContainer size={"small"}>
					<ProductInfo size={"small"}>
						<ProductTitle>Orders.co</ProductTitle>
						<ProductDescription>
							Selling landing page for Restaurant QR Menu Maker
							App
						</ProductDescription>
					</ProductInfo>
					<div>
						<ProductImage
							size={"small"}
							src={selectedCases3}
						></ProductImage>
					</div>
				</ProductContainer>
			</ProductRow>

			<ProductRow>
				<ProductContainer size={"small"}>
					<ProductInfo size={"small"}>
						<ProductTitle>The Meat House</ProductTitle>
						<ProductDescription>
							Online butcher shop for ordering baked and raw meat.
						</ProductDescription>
					</ProductInfo>
					<div>
						<ProductImage
							size={"small"}
							src={selectedCases4}
						></ProductImage>
					</div>
				</ProductContainer>

				<ProductContainer size={"large"}>
					<ProductInfo size={"large"}>
						<div>
							<ProductTitle>PDS agency</ProductTitle>
							<ProductDescription>
								Web ad blocker landing and extension
							</ProductDescription>
						</div>
						<ProductButton>Website</ProductButton>
					</ProductInfo>
					<ProductImages size={"small"} background={true}>
						<RectangleBackground>
							<ProductImage
								size={"small"}
								src={selectedCases5}
							></ProductImage>
						</RectangleBackground>
						<div>
							<ProductImage
								size={"small"}
								src={selectedCases6}
							></ProductImage>
						</div>
					</ProductImages>
				</ProductContainer>
			</ProductRow>
		</Section>
	);
};

export default SelectedCases;
