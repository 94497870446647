import { OverviewDescriptionContainer, PopupOverview } from "./styles";
import { TextRow } from "../styles";
import { useMobileDetector } from "../../../../../utils/customHooks";

const Overview = ({ popupArrow }) => {
	const isMobile = useMobileDetector();

	return (
		<PopupOverview>
			<div>
				<span>Overview</span>
			</div>
			<div>
				{isMobile ? (
					<span>
						Enhancing Venhub’s Vending Machine Solutions with a New
						and Improved Web Platform That Boosts Customer
						Engagement and Supports Business Growth.
					</span>
				) : (
					<>
						<span>Enhancing Venhub’s Vending </span>
						<span>
							Machine Solutions with a New and Improved Web
							Platform That Boosts Customer Engagement and
							Supports Business Growth.
						</span>
					</>
				)}
			</div>

			<OverviewDescriptionContainer>
				<div>
					<p>
						Venhub required a powerful and scalable platform to
						enhance their operations and customer experience. Our
						web development team designed and implemented a
						sophisticated tool that supports the management of
						vending machines, streamlines transactions, and provides
						valuable insights.
					</p>
					<p>
						The new platform features a responsive design, advanced
						inventory management, and comprehensive analytics tools,
						creating a seamless and efficient experience for both
						operators and users.
					</p>
				</div>
				<button>
					Order project <img src={popupArrow} alt="" />
				</button>
			</OverviewDescriptionContainer>
		</PopupOverview>
	);
};

export default Overview;
