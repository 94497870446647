import {
	PopupBody,
	PopupWrapper,
	CloseBtn,
	PopupContainer,
	PopupHeading,
	TextRow,
} from "./styles";
import { useEffect } from "react";

import popupCloseIcon from "../../../../Assets/Images/Popup/popupClose.svg";

// import projectInfo from "../../../../Assets/Lang/en/work/projects.json";

import popup1 from "../../../../Assets/Images/Popup/popup1.webp";
import popup2 from "../../../../Assets/Images/Popup/popup2.webp";
import popup3 from "../../../../Assets/Images/Popup/popup3.webp";
import popup4 from "../../../../Assets/Images/Popup/popup4.webp";
import popup5 from "../../../../Assets/Images/Popup/popup5.webp";
import popup6 from "../../../../Assets/Images/Popup/popup6.webp";
import popup7 from "../../../../Assets/Images/Popup/popup7.webp";
import popupArrow from "../../../../Assets/Images/Home/footer-arrow.svg";

import Services from "./Services";
import Overview from "./Overview";
import Outcomes from "./Outcomes";
import Pictures from "./Pictures";
import About from "./About";
import Results from "./Results";
import { useMobileDetector } from "../../../../utils/customHooks";

const ProjectsPopup = ({ handleClose, info }) => {
	const isMobile = useMobileDetector();

	useEffect(() => {
		document.body.style.overflow = "hidden"; // disable scrolling on the page
		const enableScroll = () => {
			document.body.style.overflow = "auto";
		};
		return enableScroll; // re-enable scrolling on the page
	}, []);

	return (
		<PopupWrapper onClick={handleClose}>
			<PopupBody onClick={(e) => e.stopPropagation()}>
				<CloseBtn>
					<img
						src={popupCloseIcon}
						alt="icon"
						onClick={handleClose}
					/>
				</CloseBtn>

				<PopupContainer>
					<PopupHeading>
						{/*{projectInfo[info.project].heading}*/}
						{isMobile ? (
							<>
								<TextRow>Venhub</TextRow>
								<TextRow>the future</TextRow>
								<TextRow>of retail</TextRow>
							</>
						) : (
							<>
								<TextRow>Venhub: the future</TextRow>
								<TextRow>of retail</TextRow>
							</>
						)}
					</PopupHeading>

					<Services />

					<Overview popupArrow={popupArrow} />

					<Outcomes />

					<Pictures
						picturesObj={[
							{ type: "large", src: popup1 },
							{ type: "small", src: popup2 },
							{ type: "small", src: popup3 },
							{ type: "large", src: popup4 },
						]}
					/>

					<About />

					<Pictures
						picturesObj={[
							{ type: "small", src: popup5 },
							{ type: "small", src: popup6 },
							{ type: "large", src: popup7 },
						]}
					/>

					<Results popupArrow={popupArrow} />
				</PopupContainer>
			</PopupBody>
		</PopupWrapper>
	);
};

export default ProjectsPopup;
