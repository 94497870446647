import styled from "styled-components";

export const PopupWrapper = styled.div`
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	background: rgba(35, 35, 35, 0.5); /* Semi-transparent background */
	backdrop-filter: blur(10px); /* Apply blur effect */
`;

export const PopupBody = styled.div`
	padding: 118px 100px 240px 100px;
	box-sizing: border-box;
	z-index: 1111;
	background-color: #ffffff;
	position: absolute;
	width: 100%;
	height: 90%;
	border-radius: 90px 90px 0 0;
	overflow-y: auto; /* Enable vertical scrolling */
	overflow-x: hidden; /* Prevent horizontal scrolling */
	max-height: 90%;

	&::-webkit-scrollbar {
		width: 0;
	}

	@media (min-width: 768px) and (max-width: 1200px) {
		padding: 118px 70px 240px 70px;
	}

	@media (min-width: 480px) and (max-width: 768px) {
		border-radius: 60px 60px 0 0;
        padding: 118px 40px 240px 40px;
	}

    @media (max-width: 480px) {
        border-radius: 30px 30px 0 0;
		padding: 118px 15px 118px 15px;
    }
`;

export const CloseBtn = styled.button`
	position: fixed;
	border: none;
	top: 10%;
	left: 0;
	right: 0;
	margin: 0 auto;
	z-index: 99;
	width: 100%;
	background-color: #ffffff;
	border-radius: 90px 90px 0 0;
	height: 96px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	max-width: 1600px;

	img {
		margin-right: calc(28px + 5.625vw);
		cursor: pointer;
		padding: 10px;
	}

    @media (min-width: 480px) and (max-width: 768px) {
        border-radius: 60px 60px 0 0;
		
		img {
            width: 18px;
            height: 18px;
		}
		
    }

    @media (max-width: 480px) {
        border-radius: 30px 30px 0 0;
    }

	@media (min-width: 1600px) {
		img {
			margin-right: 118px;
		}
	}
`;

export const PopupContainer = styled.div`
	position: relative;
	left: 0;
	right: 0;
	margin: 0 auto;
	max-width: 1400px;
`;

export const PopupHeading = styled.h1`
	display: flex;
	flex-direction: column;
	align-items: center;

	h1 {
		text-transform: uppercase;
	}

	h1:first-child {
		margin-left: 80px;
	}

	h1:nth-child(2) {
		margin-top: -80px;
	}

	@media (max-width: 1400px) {
		h1:first-child {
        margin-left: 0;
    }
		h1:nth-child(2) {
			margin-top: -40px;
		}
	}
`;

export const TextRow = styled.span`
	font-family: Inter, sans-serif;
	font-size: calc(24px + 4.5vw);
	font-weight: 500;
	line-height: calc(26px + 5.01vw);
	text-align: left;
	text-transform: uppercase;
	
	@media (max-width: 768px) {
		&:first-child {
			margin-left: 0;
		}
		&:nth-child(2) {
			margin-left: calc(-5px - 5.208vw);
		}
        &:nth-child(3) {
            margin-left: calc(10px + 16.927vw);
        }
	}

	@media (min-width: 1600px) {
		font-size: 96px;
		line-height: 116.18px;
	}
`;
