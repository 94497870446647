import styled from "styled-components";

export const Section = styled.section`
	flex: 1;
	min-height: 600px;
	width: 100%;
	box-sizing: border-box;
	padding-top: 60px;
	margin: 0 auto;
	max-width: 1400px;
`;

export const SectionHeading = styled.h3`
	position: absolute;
	left: -0.2vw;
	white-space: nowrap;
	font-size: 12.356vw;
	margin: 0;
	text-transform: uppercase;

	span {
		color: #bdbdbd;
	}
`;

export const ContactsContainer = styled.div`
	margin-top: calc(14.8vw + 70px);
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: calc(8px + 6.8vw);

	@media screen and (min-width: 1600px) {
		gap: 117px;
	}
	@media screen and (max-width: 768px) {
	flex-direction: column;
	}
`;
export const Contacts = styled.div`
	max-width: 175px;
	h6 {
		margin: 0;
		font-size: calc(8px + 1.25vw);
		font-weight: 500;
		line-height: calc(8px + 1.5vw);
		letter-spacing: 0.02em;
		color: #000000;
	}
	p {
		margin-top: 20px;
		font-size: calc(8px + 0.75vw);
		font-weight: 400;
		line-height: calc(8px + 1vw);
		letter-spacing: 0.02em;
		text-align: left;
		color: #565656;
	}
	a {
		margin-top: 24px;
		font-size: calc(8px + 0.75vw);
		font-weight: 400;
		line-height: calc(8px + 1vw);
		letter-spacing: 0.02em;
		color: #000000;
	}

	@media screen and (min-width: 1600px) {
		h6 {
			font-size: 28px;
			line-height: 33.89px;
		}
		p {
			font-size: 20px;
			line-height: 24.2px;
		}
		a {
			font-size: 20px;
			line-height: 24.2px;
		}
	}
`;
