import {
	Section,
	SectionHeading,
	TextRow,
	QuestionsWrapper,
	QuestionContainer,
	QuestionTitle,
} from "./styles";

const questions = [
	{
		title: "What services does Spesna Team offer?",
		content:
			"We specialize in UX/UI design, web and mobile development, QA engineering and testing, SEO, " +
			"and marketing. Our diverse team can handle a wide range of projects to meet your digital needs.",
	},
	{
		title: "What makes Spesna Team's UX/UI design unique?",
		content:
			"We specialize in UX/UI design, web and mobile development, QA engineering and testing, SEO, " +
			"and marketing. Our diverse team can handle a wide range of projects to meet your digital needs.",
	},
	{
		title: "How does Spesna Team approach web and mobile development?",
		content:
			"We specialize in UX/UI design, web and mobile development, QA engineering and testing, SEO, " +
			"and marketing. Our diverse team can handle a wide range of projects to meet your digital needs.",
	},
	{
		title: "What is included in your QA engineering and testing services?",
		content:
			"We specialize in UX/UI design, web and mobile development, QA engineering and testing, SEO, " +
			"and marketing. Our diverse team can handle a wide range of projects to meet your digital needs.",
	},
	{
		title: "How can Spesna Team improve my website’s SEO?",
		content:
			"We specialize in UX/UI design, web and mobile development, QA engineering and testing, SEO, " +
			"and marketing. Our diverse team can handle a wide range of projects to meet your digital needs.",
	},
	{
		title: "What kind of marketing services do you provide?",
		content:
			"We specialize in UX/UI design, web and mobile development, QA engineering and testing, SEO, " +
			"and marketing. Our diverse team can handle a wide range of projects to meet your digital needs.",
	},
	{
		title: "How do I get started with Spesna Team?",
		content:
			"We specialize in UX/UI design, web and mobile development, QA engineering and testing, SEO, " +
			"and marketing. Our diverse team can handle a wide range of projects to meet your digital needs.",
	},
	{
		title: "Can Spesna Team handle custom projects?",
		content:
			"We specialize in UX/UI design, web and mobile development, QA engineering and testing, SEO, " +
			"and marketing. Our diverse team can handle a wide range of projects to meet your digital needs.",
	},
	{
		title: "What industries do you work with?",
		content:
			"We specialize in UX/UI design, web and mobile development, QA engineering and testing, SEO, " +
			"and marketing. Our diverse team can handle a wide range of projects to meet your digital needs.",
	},
];

const Questions = () => {
	return (
		<Section>
			<SectionHeading>
				<TextRow>You have</TextRow>
				<TextRow>questions</TextRow>
				<TextRow>We have answers</TextRow>
			</SectionHeading>

			<QuestionsWrapper>
				{questions.map((question, index) => (
					<QuestionContainer key={index}>
						<QuestionTitle>{question.title}</QuestionTitle>
						<p>{question.content}</p>
					</QuestionContainer>
				))}
			</QuestionsWrapper>
		</Section>
	);
};

export default Questions;
