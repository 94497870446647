import styled from "styled-components";

export const PopupResults = styled.div`
    margin-top: 120px;
    display: flex;
    justify-content: space-between;

    div:first-child {
        width: 16.7142857%;
    }

    div:first-child span {
        font-family: Inter, sans-serif;
        font-size: calc(10px + 0.625vw);
        font-weight: 400;
        line-height: calc(12px + 0.762vw);
        text-align: left;
        text-transform: uppercase;
        display: flex;
        gap: calc(10px + 0.625vw);
    }

    div:first-child span::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: rgba(0, 0, 0, 1);
        border-radius: 50%;
    }

    div:nth-child(2) {
        width: 83.2857143%;
        display: flex;
        flex-direction: column;
    }

    div:nth-child(2) p {
        font-family: Inter, sans-serif;
        font-size: calc(24px + 1.5vw);
        font-weight: 400;
        line-height: calc(32px + 1.631vw);
        text-align: left;
        color: rgba(0, 0, 0, 1);
        margin: 0;
    }

    div:nth-child(2) > p:first-child {
        margin-left: calc(10px + 13.938vw);
    }

    div:nth-child(2) > button {
		width: 55%;
        padding: calc(8px + 0.5vw) calc(20px + 1.25vw);
        display: flex;
        gap: calc(8px + 0.5vw);
        border-radius: 90px;
        align-items: center;
        background-color: rgba(0, 0, 0, 1);
        margin-top: 44px;

        font-family: Inter, sans-serif;
        font-size: calc(14px + 0.875vw);
        font-weight: 500;
        line-height: calc(18px + 0.993vw);
        letter-spacing: 0.02em;
        text-align: left;
        color: rgba(255, 255, 255, 1);

        img {
            width: 32px;
            height: 32px;
        }
    }

    @media (max-width: 1500px) {

        div:nth-child(2) {
            & > button {
                width: 62%;
				display: flex;
				justify-content: center;
			}
            & > p:first-child {
                margin-left: 0;
            }
        }
    }

    @media (min-width: 1200px) and (max-width: 1300px) {
        div:nth-child(2) {
            & > button {
                width: 70%;
            }
        }
    }

    @media (max-width: 1200px) {
        flex-direction: column;
        div:first-child {
            width: 100%;
        }

        div:nth-child(2) {
            width: 100%;
            margin-top: 0;

            & > button {
                width: 60%;
            }
        }
    }

    @media (min-width: 768px) and (max-width: 996px) {
        div:nth-child(2) {
            & > button {
                width: 80%;
            }
        }
    }

    @media (min-width: 640px) and (max-width: 768px) {
        div:nth-child(2) {
            & > button {
                width: 90%;
            }
        }
    }

    @media (min-width: 480px) and (max-width: 640px) {
        div:nth-child(2) {
            & > button {
                padding: calc(6px + 0.5vw) calc(16px + 1.25vw);
                font-size: calc(11px + 0.875vw);
            }
        }
    }

    @media (max-width: 480px) {
        div:nth-child(2) {
            & > button {
                width: 100%;
                padding: calc(4px + 0.5vw) calc(10px + 1.25vw);
                font-size: calc(9px + 0.875vw);
                line-height: calc(12px + 0.993vw);
            }
        }
    }

    @media (max-width: 320px) {
        margin-top: 40px;
        gap: 24px;

        div:first-child span {
            font-size: 12px;
            line-height: 14.52px;
        }

        div:first-child span::before {
            width: 14px;
            height: 14px;
        }

        div:nth-child(2) {
            gap: 24px;
        }

        div:nth-child(2) p {
            font-size: 20px;
            line-height: 24.2px;
        }

        div:nth-child(2) > button {
            font-size: 12px;
            line-height: 14.52px;
            padding: 10.5px 22px;
            justify-content: center;
            gap: 10px;
            margin: 0;

            img {
                width: 16px;
                height: 16px;
            }
        }
    }

    @media (min-width: 1600px) {
        div:first-child {
            width: 16.7142857%;
        }

        div:first-child span {
            font-size: 20px;
            line-height: 24.2px;
            gap: 20px;
        }

        div:nth-child(2) {
            width: 83.2857143%;
        }

        div:nth-child(2) p {
            font-size: 48px;
            line-height: 58.09px;
        }

        div:nth-child(2) > p:first-child {
            margin-left: 233px;
        }

        div:nth-child(2) > button {
            padding: 16px 40px;
            gap: 16px;
            margin-top: 44px;

            font-size: 28px;
            line-height: 33.89px;
        }
    }
`;
