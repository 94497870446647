import {
	StepContainer,
	StepInfo,
	StepWrapper,
	SectionHeading,
	TextRow,
	Section,
} from "./styles";
import excellenceImg1 from "../../../../Assets/Images/Culture/excellence-img1.webp";
import excellenceImg2 from "../../../../Assets/Images/Culture/excellence-img2.webp";
import { useMobileDetector } from "../../../../utils/customHooks";

const ExcellenceSection = () => {
	const isMobile = useMobileDetector();

	return (
		<Section>
			<SectionHeading>
				{isMobile ? (
					<>
						<TextRow>We prioritize</TextRow>
						<TextRow>creativity</TextRow>
						<TextRow>and excellence</TextRow>
					</>
				) : (
					<>
						<TextRow>We prioritize creativity,</TextRow>
						<TextRow>and excellence</TextRow>
					</>
				)}
			</SectionHeading>
			<StepWrapper>
				<StepContainer>
					<img src={excellenceImg1} alt={"title"} />
					<StepInfo>
						<span>Unified excellence</span>
						<p>
							We value teamwork, collaborating closely with
							clients to co-create visually stunning and
							strategically impactful projects.
						</p>
						<span>Transparent commitment</span>
						<p>
							By prioritizing honesty and transparency, we
							maintain the highest ethical standards in every
							interaction, fostering trust with our clients.
						</p>
					</StepInfo>
				</StepContainer>
				<StepContainer>
					<StepInfo>
						<span>Your vision, our priority</span>
						<p>
							We dedicate ourselves to understanding your vision
							and delivering tailored solutions that align
							perfectly with your aspirations.
						</p>
						<span>Continuous improvement</span>
						<p>
							We foster a culture of continuous growth, constantly
							honing our skills and approaches to exceed present
							standards and expectations.
						</p>
					</StepInfo>
					<img src={excellenceImg2} alt={"title"} />
				</StepContainer>
			</StepWrapper>
		</Section>
	);
};

export default ExcellenceSection;
