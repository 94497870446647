import { PopupAbout } from "./styles";
import { TextRow } from "../styles";
import { useMobileDetector } from "../../../../../utils/customHooks";

const About = () => {
	const isMobile = useMobileDetector();

	return (
		<PopupAbout>
			<div>
				<span>About</span>
			</div>
			<div>
				{isMobile ? (
					<>
						<p>
							Venhub needed a modern website that could handle
							high traffic and complex transactions, we created a
							responsive and user-friendly site with features like
							secure payment processing, real-time inventory
							management, and easy-to-understand analytics.
						</p>
					</>
				) : (
					<>
						<p>Venhub needed a modern website that</p>
						<p>
							could handle high traffic and complex transactions,
							we created a responsive and user-friendly site with
							features like secure payment processing, real-time
							inventory management, and easy-to-understand
							analytics.
						</p>
					</>
				)}
			</div>
		</PopupAbout>
	);
};

export default About;
