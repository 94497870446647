import styled from "styled-components";

export const PopupOverview = styled.div`
	width: 100%;
	margin-top: 120px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	gap: 8px;

	& > div:first-child {
		width: 100%;
		display: flex;
		justify-content: flex-start;
	}

    & > div:first-child > span {
		font-family: Inter, sans-serif;
		font-size: calc(10px + 0.625vw);
		font-weight: 400;
		line-height: calc(12px + 0.762vw);
		text-align: left;
		text-transform: uppercase;
		display: flex;
		gap: calc(10px + 0.625vw);
	}

	& > div:first-child > span::before {
		content: "";
		display: inline-block;
		width: 20px;
		height: 20px;
		background-color: rgba(0, 0, 0, 1);
		border-radius: 50%;
	}

	& >div:nth-child(2) > span {
		font-family: Inter, sans-serif;
		font-size: calc(24px + 2.375vw);
		font-weight: 500;
		line-height: calc(32px + 2.689vw);
		text-align: left;
		margin-top: calc(-12px - 0.875vw);
	}

    & >div:nth-child(2) > span:first-child {
		display: flex;
		justify-content: flex-end;
		margin-right: 70px;
	}

    & >div:nth-child(2) > span:nth-child(2) {
		margin-top: -40px;
	}

	@media (max-width: 996px) {
        & >div:nth-child(2) > span {
			margin-top: 0;
		}
	}
	
	@media (max-width: 480px) {
        & >div:nth-child(2) > span {
            margin-right: 0 !important;
        }
	}
	
	@media (max-width: 320px) {
		margin-top: 40px;
		
		& > div:nth-child(2) > span {
            font-size: 24px;
            line-height: 29.05px;
        }
	}

	@media (min-width: 1600px) {
		span {
			font-size: 20px;
			line-height: 24.2px;
			gap: 20px;
		}

        & >div:nth-child(2) > span {
			font-size: 62px;
			line-height: 75.03px;
			margin-top: -30px;
		}
	}
`;

export const OverviewDescriptionContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	width: 61.9285%;
	gap: calc(12px + 2.125vw);
	margin-right: 66px;
	margin-top: 40px;

	div {
		display: flex;
		gap: calc(24px + 2.688vw);
	}

	div p {
		font-family: Inter, sans-serif;
		font-size: calc(10px + 0.625vw);
		font-weight: 400;
		line-height: calc(12px + 0.762vw);
		letter-spacing: 0.02em;
		text-align: left;
		color: rgba(0, 0, 0, 1);
	}

	div p:first-child {
		width: 46.1361%;
	}

	div p:nth-child(2) {
		width: 52.8639%;
	}

	button {
		width: 46.1361%;
		background-color: rgba(0, 0, 0, 1);
		color: rgba(255, 255, 255, 1);
		font-family: Inter, sans-serif;
		font-size: calc(14px + 0.875vw);
		font-weight: 500;
		line-height: calc(20px + 0.868vw);
		letter-spacing: 0.02em;
		text-align: left;
		padding: calc(8px + 0.5vw) calc(20px + 1.25vw);
		gap: calc(8px + 0.5vw);
		border-radius: 90px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 32px;
			height: 32px;
		}
	}

	@media (min-width: 768px) and (max-width: 1200px) {
		width: 80%;
		margin-right: 0;

		div p:first-child {
			width: 40%;
		}

		div p:nth-child(2) {
			width: 60%;
		}
	}

	@media (max-width: 768px) {
		width: 100%;
        margin-right: 0;
		
        button {
            width: 60%;
            padding: calc(4px + 0.5vw) calc(10px + 1.25vw);
        }
	}
	
	@media (max-width: 480px) {
		margin-top: 16px;
		width: 100%;

		div {
			width: 100% !important;
			flex-direction: column;
            gap: 12px;
		}
		
		div p {
			width: 100% !important;
			margin: 0;
		}

        button {
            width: 100%;
            padding: calc(4px + 0.5vw) calc(5px + 1.25vw);
        }
    }

    @media (max-width: 320px) {
        div p {
            font-size: 12px;
            line-height: 14.52px;
        }

        button {
            width: 100%;
            font-size: 12px;
            line-height: 14.52px;
			display: flex;
			justify-content: center;
            padding: 10px 28px 10px 28px;
            gap: 10px;

            img {
                width: 16px;
                height: 16px;
            }
        }
    }

	@media (min-width: 1600px) {
		width: 61.9285%;
		gap: 46px;
		margin-right: 66px;
		margin-top: 40px;

		div {
			gap: 67px;
		}

		div p {
			font-size: 20px;
			line-height: 24.2px;
		}

		div p:first-child {
			width: 46.1361%;
		}

		div p:nth-child(2) {
			width: 52.8639%;
		}

		button {
			width: 46.1361%;
			font-size: 28px;
			line-height: 33.89px;
			padding: 16px 40px;
			gap: 16px;
		}
	}
`;
